let dev = false;
if(window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1') dev = true;

dev = false; //use prod baby

const config  = {
    apiURL: "https://web_sim_api.johndews.workers.dev/",
    apiHost: "web_sim_api.johndews.workers.dev",
}

if(dev){
    config.apiURL = "http://localhost:8787/";
    config.apiHost = "localhost:8787";
}

export default config;