<template>
  <div>
    <header class="p-3 bg-dark text-white">
      <div class="container" style="max-width: 1800px">
        <div class="row">
          <div class="col-2">
            <router-link to="/">
              <span
              style="font-weight: bold; font-size: 20px; padding-right: 20px; color: white"
              >Browser<span style="color: #0a58ca">IBFEM</span></span
            >
            </router-link>
          </div>
          <div class="col-6">
            <ol class="cd-breadcrumb triangle">
              <router-link to="/import"><li :class="{ current: $route.path.includes('import') }">Import Geomery</li></router-link>
              <router-link to="/ibm/analysis"><li :class="{ current: $route.path.includes('ibm/analysis') }">Analysis</li></router-link>
              <router-link to="/ibm/mesh"><li :class="{ current: $route.path.includes('ibm/mesh') }">Mesh</li></router-link>
              <router-link to="/ibm/bc"><li :class="{ current: $route.path.includes('ibm/bc') }">Boundry Conditions</li></router-link>
              <router-link to="/ibm/solve"><li :class="{ current: $route.path.includes('ibm/solve') }">Solve</li></router-link>
              <router-link to="/ibm/results"><li :class="{ current: $route.path.includes('ibm/results') }">Results</li></router-link>
            </ol>
          </div>
          <div class="col-4">
            <div class="text-end status">
              <span class="material-icons" style="vertical-align: middle">info</span>
              <span>{{ statusMessage }}</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>


<style scoped lang="scss">
.status {
  text-align: left !important;
  width: 300px;
  line-height: 0.95em;
  padding-top: 8px;
  font-size: 12px;
  font-family: monospace;
}

* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
ol {
  display: table; /* to fit to content */
  overflow: hidden; /*  will hide bit arrow of last li if negative margin-right set there */
  white-space: nowrap; /* keep inline-block li on a single line */
  font-size: 0.85em;
  margin: auto;
}
ol li {
  display: inline-block; /* be an inline boxe */
  margin-right: -1.35em; /* overlay them to reduce white gap in between. tune in em to your needs */
  line-height: 0.95em; /* tune to your needs */
  padding: 1em 1.9em; /* should be fine with gradient bg */
  cursor: grab;
  background: linear-gradient(
        70deg,
        transparent 1em,
        lightgray 1em,
        lightgray 50%,
        transparent 50%
      )
      top left no-repeat,
    linear-gradient(
        -110deg,
        transparent 1em,
        lightgray 1em,
        lightgray 55%,
        transparent 55%
      )
      top no-repeat,
    linear-gradient(
        -70deg,
        transparent 1em,
        lightgray 1em,
        lightgray 50%,
        transparent 50%
      )
      bottom left no-repeat,
    linear-gradient(
        110deg,
        transparent 1em,
        lightgray 1em,
        lightgray 55%,
        transparent 55%
      )
      bottom no-repeat;
  /*bg arrow color is lightgray, to chanage it, change each one of theme in gradient */
  background-size: 100% 51.5%; /* resize each piece a gradient to draw the shape */
}

$back-color: #038eff;

ol li.current,
ol li:hover {
  background: linear-gradient(
        70deg,
        transparent 1em,
        $back-color 1em,
        $back-color 50%,
        transparent 50%
      )
      top left no-repeat,
    linear-gradient(
        -110deg,
        transparent 1em,
        $back-color 1em,
        $back-color 55%,
        transparent 55%
      )
      top no-repeat,
    linear-gradient(
        -70deg,
        transparent 1em,
        $back-color 1em,
        $back-color 50%,
        transparent 50%
      )
      bottom left no-repeat,
    linear-gradient(
        110deg,
        transparent 1em,
        $back-color 1em,
        $back-color 55%,
        transparent 55%
      )
      bottom no-repeat;
  /* bg arrow color is now red */
  background-size: 100% 51.5%;
}
ol li.current a,
ol li:hover a {
  color: white;
}
a {
  text-decoration: none;
  color: black;
  font-variant: small-caps;
  font-weight: 500;
  transition: 0.2s;
}
</style>

<script>
import SystemStatus from "./../FEM/display/status";
export default {
  mounted() {
    let self = this;
    setInterval(function () {
      self.statusMessage = SystemStatus.get();
    }, 200);
  },
  data() {
    return {
      statusMessage: "System Initilizing",
      url: this.$router.currentRoute.path,
    };
  },
};
</script>
