<template>

    <div class="popup" @click="close()" v-if="hide == false">
        <div class="container content">
            <button class="btn btn-primary" v-for="(type, index) in types">{{type.name}} {{index}}</button>
        </div>
    </div>
  
</template>




<script>
export default {
    data(){
        return {
            types: [{
                name: "Traction"
            },
            {
                name: "Pressure"
            }],
            typeSelected: null,
            hide: false
        }
    },
    mounted(){
        console.log("here in mounted")
    },
    methods:{
        close(){
            this.$emit("close")
            this.hide = true;
        }
    }

}
</script>





<style lang="scss" scoped>

.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    padding: 5vw;

}

.content{
    background: white;
    width: 100%;
    min-height: 100px;
}

</style>