import axios from 'axios';
import config from './config';
import localforage from "localforage";
const JSZip = require("jszip");
const JSZipUtils = require("jszip-utils");

let apiUrl = config.apiURL+'api/';
let token = null;
let user = null;
const cdnUrl = config.apiURL;


const errorHandler = (err) => {
    console.log(err);
    err.data = err.response.data;

    return {failed: true, ...err};
};

const dataHandler = (a) => {

    return a;
}

const get = (url, params) => {
    return axios.get(apiUrl + url, { params }).catch(errorHandler).then(dataHandler);
}

const getFromCDN = (url, params, encoding) => {
    return axios.get(cdnUrl + url, { params,   responseType: 'arraybuffer',
    responseEncoding: 'binary' }).catch(errorHandler).then(dataHandler);
}

const post = (url, data) => {
    return axios.post(apiUrl + url, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).catch(errorHandler).then(dataHandler);
}

const postFormData = (url, data, config) => {
    return axios.post(apiUrl + url, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }, ...config
    }).catch(errorHandler).then(dataHandler);
}

const getToken = () => {
    if(token) return token;
    let storedToken = localStorage.getItem('token');
    if(!storedToken) return null;
    token = storedToken;
    return storedToken;
}

const setToken = (t) => {
    token = t;
    localStorage.setItem('token', t);
};

const createDocument = async (collection, doc) => {
    let res = await post('create', {collection, doc});
    if(res.failed) return res;
    return res.data;
}

const uploadModel = async (payload, documentObject, imageURI, progressCallback) => {

    let zip = new JSZip();
    let input = JSON.stringify(documentObject);
    zip.file("data.json", input);
    let document = await zip.generateAsync({ type: "blob", compression: "DEFLATE", compressionOptions : { level: 9 }  });

    let files = new File([document], "name.zip");
    var formData = new FormData();
    formData.append('documentZip', files);
    formData.append("payload", JSON.stringify(payload));
    let imageBlob = dataURItoBlob(imageURI);
    formData.append("image", imageBlob);

    let res = await postFormData('upload', formData, {
        onUploadProgress: function(progressEvent) {
            var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            progressCallback(percentCompleted);
        }
    })
    if(res.failed) return res;
    return res.data;
}


const loadZipFile = async (url) => {
    return new Promise((resolve, reject) => {
        JSZipUtils.getBinaryContent(cdnUrl + url, function(err, data) {
            if(err) reject(err); // or handle err
            JSZip.loadAsync(data).then(resolve);
        });
    });
}

const getJSONFromZip = async (zip) => {
    let jsonString = await zip.file("data.json").async("string");
    return JSON.parse(jsonString);
}

const importPart = async(id) => { //so dirty but works
    //let zipData = await getFromCDN('static/model_' + id + '.zip', true);
    let data = await (loadZipFile('static/model_' + id + '.zip').then(getJSONFromZip));

    if(!data.mesh || !data.part || !data.bc || !data.analysis) throw "missing required data";
    localStorage.setItem("mesh", data.mesh)
    localStorage.setItem("test-geometry", data.part)
    localStorage.setItem("BC", data.bc)
    await localforage.setItem("results", data.results)
    await localforage.setItem("CMat", data.CMat);
    await localforage.setItem("analysis", data.analysis);
    return true;
}

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
}


export default { get, post, getToken, setToken, createDocument, uploadModel, importPart };