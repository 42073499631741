<template>
  <div v-if="document && !loading" class="container pt-5 pb-5">
    <h2>Select a Part from: {{ document.name }}</h2>

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/import">All Documents</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ document.name }}
        </li>
      </ol>
    </nav>

    <p>
      Note: a single document can contain many parts, drawings, and assemblies.
      This program supports only 2d structures laying in the z=0 plane. Units
      are assumed to be in meters. All entries must follow
      <a href="https://en.wikipedia.org/wiki/International_System_of_Units"
        >SI units</a
      >.
    </p>
    <div class="row">
      <div
        class="col-2"
        v-for="part in document.parts"
        @click="importPart(document, part)"
      >
        <div class="document center">
          <img class="thumb" :src="part.thumbnail" alt="" />
          <h6 class="pt-1">{{ part.name }}</h6>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Texture>{{ message }}</Texture>
  </div>
</template>

<script>
import onShape from "../../FEM/onshape";
import Texture from "../loading.vue";
import talk from "../../talk.js";
import config from "../../config.js";

let replaceUrl = function (url) {
  if (!url) return "";
  let token = talk.getToken();
  return url.replace("https://cad.onshape.com/", config.apiURL+'os/')+"?token="+token;
};

export default {
  components: { Texture },
  data() {
    return {
      id: this.$route.params.id,
      documents: [],
      document: null,
      message: "Getting Document Parts",
      loading: false,
    };
  },

  methods: {
    selectDocument(document) {
      this.$router.push({ path: "/import/" + document.id });
    },
    importPart(document, part) {
      let self = this;
      this.loading = true;
      this.message = "Importing Part";
      this.$forceUpdate();
      onShape.importPart(document, part).then((x) => {
        self.$router.push({ path: "/ibm/mesh" });
      });
    },
  },

  async mounted() {
    this.document = await onShape.getDocument(this.id);
    for (let part of this.document.parts) {
      part.thumbnail = replaceUrl(
        part.thumbnailInfo.sizes.find((s) => s.size == "300x300").href
      );
    }
  },
};
</script>


<style scoped lang="scss">
.thumb {
  width: 300px;
  margin: auto;
  display: block;
  max-width: 100%;
  min-height: 100px;
  min-width: 100%;
  background: white;
}

.center {
  text-align: center;
}

.document {
  padding: 20px;
  margin: 5px;
  border: 2px solid #ccc;
  cursor: grab;
  word-wrap: break-word;
  min-height: 100px;
  h6 {
    min-height: 30px;
  }
}

.document:hover {
  border: 2px solid rgb(133, 188, 206);
}
</style>