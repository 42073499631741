import axios from "axios";
import config from "../../config";
import talk from "../../talk";
const api = config.apiURL+"os/api/"
const tokenStore = null;

let get = async function(url, urlParams, token){
    if(!token) token = tokenStore || localStorage.getItem("token");
    if(!token) throw "No Token";
    let req = await axios.get(api+url, {
        headers: { 'Auth': token },
        params: urlParams
    });
    console.log(urlParams, req.data)
    return req.data;
}


let signOut = async function(){
    localStorage.removeItem("token");
    localStorage.removeItem("sessioninfo");
}

let refreshToken = async function(){
    let res = await get("refreshtoken");
    if(res.token) {
        talk.setToken(res.token);
    }
}


let saveUserDetails = async function(){
    let details = await get("users/sessioninfo")
    if(details.name){
        localStorage.setItem("sessioninfo", JSON.stringify(details));
    }
    return details;
}

export default { get, saveUserDetails, signOut, refreshToken};
