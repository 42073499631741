let { scale2D } = require('./utils');
import localforage from 'localforage';
// 2d plane stress material matrix
let linear2DPlaneStress = (E, v, t) => {
    let res = [
        [1, v, 0],
        [v, 1, 0],
        [0, 0, (1 - v) / 2]
    ]
    return scale2D(t * E / (1 - v * v), res)
}

// 2d plane strain material matrix
let linear2DPlaneStrain = (E, v, t) => {
    let res = [
        [1 - v, v, 0],
        [v, 1 - v, 0],
        [0, 0, 1 - 2 * v]
    ]
    let k = E / ((1 + v) * (1 - 2 * v));
    return scale2D(k, res)
}

let getStoredMaterialMatrix = async () => {
    let C = await localforage.getItem('CMat');
    if (C && C[0]) return C;
    throw "Missing Material Matrix";
}

module.exports = { getStoredMaterialMatrix, linear2DPlaneStress, linear2DPlaneStrain }