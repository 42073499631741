import VueRouter from 'vue-router'
import about from './about.vue';
import IBM from './IBM/IBM.vue';
import loadDocuments from './IBM/loadDocuments.vue';
import loadDocument from './IBM/loadDocument.vue';
import mesh from './IBM/selectMesh.vue';
import BC from './IBM/boundaryCondition.vue';
import results from './IBM/results.vue';
import analysis from './IBM/analysis.vue';
import save from './IBM/save.vue';
import list from './IBM/list.vue';



import authorize from './OnShape/authorize.vue';
import login from './OnShape/login.vue';
import user from './OnShape/user.vue';


const routes = [
  { path: '/', component: login },
  { path: '/ibm/solve', component: IBM },
  { path: '/about', component: about },
  { path: '/import', component: loadDocuments },
  { path: '/import/:id', component: loadDocument },
  { path: '/ibm/mesh', component: mesh },
  { path: '/ibm/bc', component: BC },
  { path: '/ibm/results', component: results },
  { path: '/ibm/analysis', component: analysis },
  { path: '/ibm/save', component: save },
  { path: '/ibm/list', component: list },




  { path: '/authorize', component: authorize },
  { path: '/login', component: login },
  { path: '/logout', component: login },
  { path: '/user', component: user }

]
const router = new VueRouter({
  routes,
  mode: 'history'

})

module.exports = router;