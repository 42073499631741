<template>
  <div class="container" style="max-width: 650px">
    <div class="card mt-4">
      <div class="card-body">
        <div>
          <h4 class="pb-3">Get started by importing some geometry:</h4>
          <div class="center">
            <a :href="signInUrl" class="btn btn-dark"
              >Sign In Via
              <img
                src="~/assets/onshape_logo.svg"
                class=""
                height="25px"
                alt=""
            /></a>
            <span class="p-2">or</span>
            <router-link to="/ibm/list" class="btn btn-primary"
              >Import an Example</router-link
            >
          </div>
          <br />
          <p>
            <b>What Is OnShape?</b> OnShape is a company which offers a free
            cloud based CAD service (<a
              href="https://www.onshape.com/en/sign-up"
              >create an account</a
            >). This program integrates to get geometry from parts in your
            OnShape account for simulation.
          </p>
        </div>
      </div>
    </div>
    <About></About>
  </div>
</template>

<script>
import onShapeOAuth from "./OnShapeOAuthRequest.js";
import About from "./../about.vue";
import config from "./../../config.js";

export default {
  components: { About },
  data() {
    let appParams = "";
    if (window.location.hostname == "localhost")
      appParams = "?app=http://localhost:1234";
    return {
      signInUrl: config.apiURL + "os/login" + appParams,
    };
  },
  async mounted() {
    let token = localStorage.getItem("token");
    if (this.$route.path.includes("logout")) {
      onShapeOAuth.signOut();
    } else if (token) {
      await onShapeOAuth.refreshToken();
      this.$router.push({ path: "/user" });
    }
  },
};
</script>

<style>
</style>