<template>
  <div>
    <div class="toolbar">
      <div class="form-item">
        Select An Edge to Apply a Boundry Condition
      </div>

     
    </div>
    <div id="inset"></div>

    <div class="row g-0">
      <div class="col-8">
        <div id="3d-view" class="stage" ref="stageElement"></div>
      </div>
      <div class="col-4 p-3">
        <h5 class="center">Boundry Conditions</h5>
        <p class="center">Select an edge to add a new boundry condition</p>

        <div class="card card-body bg-light" v-if="newBC">
          {{ newBC }}
          <select v-model="newBC.type">
            <option value="traction">Traction</option>
            <option value="fixed">Fixed</option>
            <option disabled value="pressure">Pressure</option>
          </select>
          <div v-if="newBC.type == 'traction'">
            <input type="text" v-model="newBC.value.x" placeholder="T_x" />
            <input type="text" v-model="newBC.value.y" placeholder="T_y" />
          </div>
          <div v-else-if="newBC.type == 'fixed'">
            <input
              type="text"
              v-model="newBC.value.x"
              placeholder="U (X-Disp.)"
            />
            <input
              type="text"
              v-model="newBC.value.y"
              placeholder="V (Y-Disp.)"
            />
          </div>
          <button
            class="btn btn-primary"
            :disabled="newBC.value.x == null || newBC.value.y == null"
            @click="addBC(newBC)"
          >
            Add
          </button>
        </div>

        <div class="card card-body bg-light">
          <ol>
            <li v-for="(BC, i) in boundryConditions">
              <h5><small class="text-muted h6">{{ BC.edgeId }}</small> {{ BC.type }}</h5>

              <div class="row">
                <div class="col-4"><input type="text" placeholder="T_x" v-model="BC.value.x" /></div>
                <div class="col-4"><input type="text" placeholder="T_y" v-model="BC.value.y" /></div>
                <div class="col-4"><button class="btn btn-sm btn-danger" @click="removeBC(i)">
                Remove
              </button></div>
              </div>

              
              
              


            </li>
          </ol>
          <button class="btn btn-primary" @click="saveBC()">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IBM from "../../FEM/IBM";
import Display from "../../FEM/display/FourNode";
import statusMessage from "../../FEM/display/status";
import IBM_WORKER from "../../FEM/workers/IBMWorkerLoader.js";
import onShape from "../../FEM/onshape";
import selectBCType from "./selectBCType.vue";

let InitScene = require("../../FEM/display/initalizeScene.js");

export default {
  components: {
    selectBCType,
  },
  data() {
    let xDiv = 101;
    if (localStorage.getItem("mesh"))
      xDiv = JSON.parse(localStorage.getItem("mesh")).xDiv;
    if (xDiv > 900) xDiv = 900;
    let BC_stored = JSON.parse(localStorage.getItem("BC")) || [];

    return {
      camera: null,
      config: {
        E: 10 * 10e9,
        v: 0.3,
        thickness: 0.1,
        xDiv: 250,
        yDiv: 9,
        length: 6,
        height: 0.1,
        lowerIntergration: false,
        tipLoad: 500000,
        enableDynamic: false,
        plotMirror: true,
        ratio: 1,
        xElementCount: xDiv,
      },
      elementCount: 0,
      nodeCount: 0,
      area: 0,
      newBC: null,
      boundryConditions: BC_stored,
    };
  },
  watch: {},
  methods: {
    clear() {
      let scene = this.scene;
      scene.clear();
    },
    selectEdge(edgeId) {
      this.newBC = {
        edgeId: edgeId,
        type: "traction",
        value: { x: null, y: null },
      };
    },
    removeBC(index) {
      this.boundryConditions.splice(index, 1);
    },
    addBC(newBC) {
      this.boundryConditions.push(newBC);
      this.plotBC();
      this.saveBC();
    },
    saveBC() {
      localStorage.setItem("BC", JSON.stringify(this.boundryConditions));
      this.plotBC();
    },
    plotBC() {
      let scene = this.scene;
      for (let BC of this.boundryConditions) {
        let type = BC.type;
        let edgeId = BC.edgeId;
        Display.plotBC(this.scene, this.part, edgeId, BC);
      }
    },
    async setMesh(xDiv = 100) {
      if (xDiv > 900) xDiv = 900;
      this.config.xElementCount = xDiv;
      this.$forceUpdate();
      this.clear();

      xDiv = Number(xDiv);
      let scene = this.scene;
      console.time("OnShape Request");
      let partData = await onShape.test(); //console.log("PartData", partData);
      this.part = partData;
      console.timeEnd("OnShape Request");
      Display.plotEdges(scene, partData, -0.00001, 0x00ff00);
    
      this.plotBC();


      let showInside = false;

    },
  },
  beforeDestroy() {
    if (this.closeListeners) this.closeListeners();
  },
  async mounted() {
    let { controls, camera, runtime, scene, renderer } = InitScene();
    let loadWorker = IBM_WORKER();
    this.scene = scene;
    this.camera = camera;
    let self = this;

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    function onMouseMove(event) {
      const rect = document.getElementById("3d-view").getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      mouse.x = (x / document.getElementById("3d-view").clientWidth) * 2 - 1;
      mouse.y = (y / document.getElementById("3d-view").clientHeight) * -2 + 1;
    }

    function runTracer() {
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children);
      let i = 0;
      for (let i in intersects)
        if (intersects[i].object && intersects[i].object.edgeId) break;
      if (!intersects[i] || !intersects[i].object.edgeId) {
        console.log("no intersection", intersects);
        return;
      }

      let lastColor = intersects[i].object.material.color.getHex();
      intersects[i].object.material.color.set(0xff0000);
      self.selectEdge(intersects[i].object.edgeId);
      setTimeout(() => {
        intersects[i].object.material.color.set(lastColor);
      }, 2000);
    }

    window.addEventListener("mousemove", onMouseMove, false);
    document
      .getElementById("3d-view")
      .addEventListener("click", runTracer, false);

    this.closeListeners = function () {
      window.removeEventListener("mousemove", onMouseMove, false);
      document
        .getElementById("3d-view")
        .removeEventListener("click", runTracer, false);
    };

    let lastSize = null;
    let updateCanvasSize = function () {
      let width = document.getElementById("3d-view").clientWidth;
      let height = document.getElementById("3d-view").clientHeight;
      if (lastSize != width + height) {
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        lastSize = width + height;
      }
    };

    //repeat at 60fps
    let render = function () {
      controls.update();

      updateCanvasSize();
      runtime();
      renderer.render(scene, camera);
      requestAnimationFrame(render);
    };
    render();

    this.IBM_DropIn = await loadWorker;
    await this.setMesh(this.config.xElementCount);
  },
};
</script>


<style lang="scss">
.stage {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  canvas {
  }
}

.form-item {
  font-size: 13px;
  display: inline-block;
  input {
    width: 60px;
  }
}

.form-item.spaced {
  border-left: 1px solid white;
  padding-left: 10px;
  margin-left: 10px;
}

.toolbar {
  background: rgb(31, 31, 31);
  width: 100%;
  color: white;
  padding: 15px;
  font-size: 12px;
  margin: auto;
  text-align: center;
}

#inset {
  position: absolute;
}
.stageContainer {
}
</style>