import Earcut from "earcut";
import lodash from "lodash";
import * as math from "mathjs";
import { getStoredMaterialMatrix } from "./MaterialMatrix"
import { COO } from "@thi.ng/sparse";
import axios from "axios";
import { Lut } from "three/examples/jsm/math/Lut.js"
import localforage from "localforage";
const gaussQ = require("gauss-quadrature")
const quadratureGen = require('triangle-quadrature') //easy computation of evulation points
const polygonClipping = require('polygon-clipping') //get intersection of part and elements
const numeric = require('numeric');


let storeRepeatedStiffness = null;

let setStatus = (msg) => { if (self && self.postMessage) self.postMessage({ type: "update", msg }) };

const createMesh = function (boudingBox, x_subdivions, y_subdivions) {
    setStatus("Generating mesh...");

    let pre_x_length = Math.abs(boudingBox.mins[0] - boudingBox.maxs[0]);
    let pre_element_x_length = pre_x_length / x_subdivions;

    let a = 0.01; //this avoids overlap issues
    if (x_subdivions < 30) a = 0.1;
    let x_diff = pre_element_x_length * a;
    let y_diff = x_diff;

    let x_length = Math.abs(boudingBox.mins[0] - boudingBox.maxs[0]) + x_diff;
    let y_length = Math.abs(boudingBox.mins[1] - boudingBox.maxs[1]) + y_diff;
    let x_start = boudingBox.mins[0] - x_diff / 2;
    let y_start = boudingBox.mins[1] - y_diff / 2;
    let element_x_length = x_length / x_subdivions;
    if (y_subdivions == undefined) {
        y_subdivions = Math.ceil(y_length / element_x_length);
    }
    let element_y_length = y_length / y_subdivions;
    let nodes = [];
    let elements = [];
    for (let i = 0; i <= x_subdivions; i++) {
        for (let j = 0; j <= y_subdivions; j++) {
            nodes.push({
                orgPos: [i * element_x_length + x_start, j * element_y_length + y_start]
            })

        }
    }
    for (let i = 0; i < x_subdivions; i++) {
        for (let j = 0; j < y_subdivions; j++) {
            elements.push({
                nodes: [i * (y_subdivions + 1) + j, (i + 1) * (y_subdivions + 1) + j, (i + 1) * (y_subdivions + 1) + j + 1, i * (y_subdivions + 1) + j + 1]
            })
        }
    }
    return { nodes, elements, area: element_x_length * element_y_length, xLength: element_x_length, yLength: element_y_length };
}


let getElementCenter = function (mesh, element) {
    let nodes = mesh.nodes;
    let center = [0, 0];
    for (let i = 0; i < element.nodes.length; i++) {
        let node = nodes[element.nodes[i]];
        center[0] += node.orgPos[0];
        center[1] += node.orgPos[1];
    }
    center[0] /= element.nodes.length;
    center[1] /= element.nodes.length;
    return center;
}

// returns true if the line from (a,b)->(c,d) intersects with (p,q)->(r,s)
function intersects(a, b, c, d, p, q, r, s) {
    var det, gamma, lambda;
    det = (c - a) * (s - q) - (r - p) * (d - b);
    if (det === 0) {
        return false;
    } else {
        lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
        gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
        return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
    }
};

function getIntersection(x1, y1, x2, y2, x3, y3, x4, y4) {
    var ua, ub, denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    if (denom == 0) {
        return null;
    }
    ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
    ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
    return [
        x1 + ua * (x2 - x1),
        y1 + ua * (y2 - y1)
    ];
}


let checkIfPointIsInPart = function (mesh, point, partData) { // draw line and count intersections. https://en.wikipedia.org/wiki/Point_in_polygon
    let intersections = 0;
    let offset = ((partData.boudingBox.maxs[0] - partData.boudingBox.mins[0]) * 8);
    let rPoint = [point[0] + offset, point[1] + offset * Math.random()];
    for (let edge of partData.partData.edges) {
        for (let i = 1; i < edge.vertices.length; i++) {
            let lPoint = edge.vertices[i - 1];
            let cPoint = edge.vertices[i];
            let collide = intersects(lPoint[0], lPoint[1], cPoint[0], cPoint[1], point[0], point[1], rPoint[0], rPoint[1]);
            if (collide) intersections++;
        }
    }



    return intersections % 2 == 1;
}


let checkIfNodeIsOnExtremes = function (point, partData) {
    let extremes = partData.boudingBox;
    if (check(point[0], extremes.mins[0]) || check(point[0], extremes.maxs[0])) return true;
    if (check(point[1], extremes.mins[1]) || check(point[1], extremes.maxs[1])) return true;
    return false;
}


let getStressFunction = function (D, B_func, q) {
    //for 2d solid mechanics D is [3,3]
    //for 4 node elements: q is [8,1]. [rows, cols], B_func is [3,8]
    return function (x, y) {
        let B = B_func(x, y);
        let stress = math.multiply(math.multiply(D, B), q);
        return stress.toArray();
    }
}


let getVonMises = (s) => { //based on viogt [S] notation 
    //return Number(s[0]);
    return Math.sqrt(s[0] * s[0] - s[0] * s[1] + s[1] * s[1] + 3 * s[2] * s[2])
}


let getElementStressFunction = function (mesh, D, element) {
    let elements = mesh.elements;
    let nodes = mesh.nodes;
    let B_func = buildBFunc(mesh, element); //(x,y) within element!
    let stress_func = getStressFunction(D, B_func, element.q);
    return stress_func;
}


let getInternalTriangles = function (mesh, element) {
    let points = element.nodes.map(node => mesh.nodes[node].orgPos);
    let center = getElementCenter(mesh, element);
    // let triangles = new Array(4); //4 triangles
    // triangles[0] = ([points[0], center, points[1]]);
    // triangles[1] = ([points[1], center, points[2]]);
    // triangles[2] = ([points[2], center, points[3]]);
    // triangles[3] = ([points[3], center, points[0]]);
    let triangles = new Array(2);
    triangles[0] = [points[0], points[1], points[2]];
    triangles[1] = [points[2], points[3], points[0]];
    return [triangles.flat(1)];
}


let getPolygonRegion = function (mesh, partData, ploygon, includeInside = false, ignoreInside = false) {
    setStatus("Calculating polygon region for each element...");
    let points = [];
    let elements = mesh.elements;
    let allTrangulations = []
    let totalArea = 0;
    for (let element of elements) {

        let area = 0;
        let altArea = 0;
        let elementTriangulations = [];
        let elementPloygon = [];
        let evaulations = [];


        if (element.outside == true) {    ////////////////////////////////// EXTERIOR ELEMENT
            for (let node of element.nodes) elementPloygon.push(mesh.nodes[node].orgPos);
            elementPloygon.push(elementPloygon[0]);
            let raw = polygonClipping.intersection(ploygon, [elementPloygon]);
            for (let ploygon of raw) {
                let trangulations = ploygon.map(x => {
                    //altArea+=computePolyArea(x);
                    return Earcut(x.flat()).map(s => x[s]);
                })
                for (let triangulation of trangulations) {
                    lodash.chunk(triangulation, 3).forEach(triangle => {
                        let evaulation = triangularGuassIntergration(triangle, () => { 1 }, 2)
                        evaulations.push(...evaulation);
                    });
                }
                elementTriangulations.push(...trangulations);
            }
        } else {                        //////////////////////////////// INTERNAL ELEMENT
            element.area = mesh.area; element.areaRatio = 1;
            totalArea += mesh.area;

            elementTriangulations = getInternalTriangles(mesh, element);
            lodash.chunk(elementTriangulations[0], 3).forEach(triangle => {
                let evaulation = triangularGuassIntergration(triangle, () => { 1 }, 2)
                evaulations.push(...evaulation);
            });

            if (includeInside == true || element.issue) allTrangulations.push(...elementTriangulations);
        }

        element.trangulations = elementTriangulations;
        element.evaulations = evaulations;

        if (!element.outside) continue; //END IF INTERAL ELEMENT

        for (let gPoint of evaulations) area += gPoint[3]; //add all the weights = the area of the triangle
        totalArea += area
        element.area = area;
        element.areaRatio = area / mesh.area;
        if (element.areaRatio < 0.005) element.issue = true;
        allTrangulations.push(...elementTriangulations);
    }

    elements = getEdgesForEachElement(partData, mesh)
    return { elements, points, allTrangulations, mesh, totalArea };
}




let getElementIntersectionsRegion = function (mesh, partData, ploygon) {

    let points = [];
    let elements = mesh.elements;
    for (let element of elements) {
        if (!element.outside) continue;
        let lines = [];
        for (let i in element.nodes) {
            let last = i - 1;
            if (last < 0) last = element.nodes.length - 1;
            lines.push([
                mesh.nodes[element.nodes[last]].orgPos,
                mesh.nodes[element.nodes[i]].orgPos
            ])
        }

        let intersectionWith = [];
        let intersectionPoints = [];
        let elInt = 0;
        for (let edge of partData.partData.edges) {
            let intersections = 0;
            for (let i = 1; i < edge.vertices.length; i++) {
                for (let line of lines) {
                    let lPoint = edge.vertices[i - 1];
                    let cPoint = edge.vertices[i];
                    let collide = intersects(...line[0], ...line[1], lPoint[0], lPoint[1], cPoint[0], cPoint[1]);
                    if (collide) {
                        let point = getIntersection(...line[0], ...line[1], lPoint[0], lPoint[1], cPoint[0], cPoint[1])
                        intersectionPoints.push(point);
                        intersections++;

                    }
                }
            }
            elInt += intersections;
            if (intersections > 0) intersectionWith.push(edge);
        }
        if (elInt == 1) {
            alert("Alert only one intersection point");
        }
        //if(intersectionPoints.length > 2) alert("Error in boarder element region determination. More than 2 intersection points.");
        if (intersectionPoints.length > 2) {
            element.issue = true;
            console.log(intersectionPoints)
            console.log(intersectionWith)
            console.log(lines)
        };
        points = [...points, ...intersectionPoints];

    }
    return { elements, points };
}

let getElementIntersectionsWithLine = function (mesh, element, segment) {
    let points = [];
    let lines = []; //4 for rectangle
    for (let i in element.nodes) {
        let last = i - 1;
        if (last < 0) last = element.nodes.length - 1;
        lines.push([
            mesh.nodes[element.nodes[last]].orgPos,
            mesh.nodes[element.nodes[i]].orgPos
        ])
    }
    for (let line of lines) {
        let lPoint = segment[0];
        let cPoint = segment[1];
        if (intersects(...line[0], ...line[1], lPoint[0], lPoint[1], cPoint[0], cPoint[1])) {
            let point = getIntersection(...line[0], ...line[1], lPoint[0], lPoint[1], cPoint[0], cPoint[1])
            points.push(point);
        }
    }
    return points;
}



let removeElements = function (mesh, partData, ploygonRaw) {
    let ploygon = ploygonRaw[0].map(x => { if (x % 2 != 0) { x.push(x[0]) } return x; }).flat(1); //make closed loop polygons and add all to one array
    let elements = mesh.elements;
    let nodes = mesh.nodes;
    let newElements = [];
    let count = 0;
    for (let node of mesh.nodes) {
        let point = node.orgPos;
        let isInPart = checkIfPointIsInPart(mesh, point, partData, ploygon);
        node.isInPart = isInPart;
        if (isInPart) count++;
    }
    for (let element of elements) {
        let inside = 0;
        let center = getElementCenter(mesh, element)
        let centerIsInside = checkIfPointIsInPart(mesh, center, partData, ploygon); //double check kinda
        for (let node of element.nodes) {
            if (nodes[node].isInPart) {
                inside++;
            }
            if (checkIfNodeIsOnExtremes(nodes[node].orgPos, partData)) {
                element.outside = true;
            }
        }
        if (inside < 4) element.outside = true;
        if (inside > 0 || centerIsInside) newElements.push(element);
    }
    return { ...mesh, elements: newElements };
}

let check = function (value, target) {
    if (!target) target = 0.0;
    return Math.abs(target - value) < 0.00001;
}

let makePolygonFromTriangles = function (triangles) {
    let polygons = [];
    for (let triangle of triangles) {
        let polygon = [[]];
        for (let node of triangle) {
            polygon[0].push([node[0], node[1]]);
        }
        polygons.push(polygon);
    }
    return polygonClipping.union(...polygons);
}

let triangularGuassIntergration = function (triangle, func, order = 3) {
    triangle = triangle.map(x => [x[0], x[1], 0]);
    let rules = quadratureGen(triangle, order);
    let evaulations = [];
    for (let i in rules.positions) evaulations.push([...rules.positions[i], rules.weights[i]]);
    return evaulations;
}

//http://www.ce.memphis.edu/7117/notes/presentations/chapter_06b.pdf
let buildB = function (x, y, width, height, center) {
    let b = width / 2;
    let h = height / 2;
    x = x - center[0];
    y = y - center[1];
    let B = math.matrix([
        [-(h - y), 0, (h - y), 0, (h + y), 0, -(h + y), 0],
        [0, -(b - x), 0, -(b + x), 0, (b + x), 0, (b - x)],
        [-(b - x), -(h - y), -(b + x), (h - y), (b + x), (h + y), (b - x), -(h + y)],
    ]);
    return math.multiply((1 / (4 * b * h)), B)
}

let buildBFunc = function (mesh, element) {
    let width = mesh.xLength;
    let height = mesh.yLength;
    let center = getElementCenter(mesh, element);
    return function (x, y) {
        return buildB(x, y, width, height, center);
    }
}

let buildNFunc = function (mesh, element) {
    let center = getElementCenter(mesh, element)
    let b = mesh.xLength / 2;
    let h = mesh.yLength / 2;
    let k = 1 / (4 * b * h);
    return (x, y) => {
        x = x - center[0];
        y = y - center[1];
        let N = math.matrix([k * (b - x) * (h - y), k * (b + x) * (h - y), k * (b + x) * (h + y), k * (b - x) * (h + y)]);
        return N;
    }
}

let buildNFunc2D = function (mesh, element) {
    let center = getElementCenter(mesh, element)
    let b = mesh.xLength / 2;
    let h = mesh.yLength / 2;
    let k = 1 / (4 * b * h);
    return (x, y) => {
        x = x - center[0];
        y = y - center[1];
        let N = math.matrix([
            [k * (b - x) * (h - y), 0, k * (b + x) * (h - y), 0, k * (b + x) * (h + y), 0, k * (b - x) * (h + y), 0],
            [0, k * (b - x) * (h - y), 0, k * (b + x) * (h - y), 0, k * (b + x) * (h + y), 0, k * (b - x) * (h + y)]
        ]);
        return N;
    }
}

let hook = async function (mesh, partData, BC) {
    let elements = mesh.elements;
    let nodes = mesh.nodes;
    let newElements = [];
    let C = await getStoredMaterialMatrix()
    console.log("elements", elements)
    console.time("Element stiffness assembly");
    storeRepeatedStiffness = null; //reset
    for (let i in elements) {
        elements[i].stiffness = evaluateTrangleStiffness(mesh, elements[i], partData, C);
        elements[i].load = evaulateElementBC(mesh, elements[i], partData, BC, C);
    };
    console.timeEnd("Element stiffness assembly");
    return await assembleElements(mesh, partData, C);
}

let evaluateTrangleStiffness = function (mesh, element, partData, C) {
    if (!element.outside && storeRepeatedStiffness) return storeRepeatedStiffness; //saves time since all internal elements have same stiffness
    let center = getElementCenter(mesh, element)
    let elementStiffness = math.zeros(8, 8);
    //console.log("center",center)
    for (let evaluation of element.evaulations) {
        //console.log("evaluation",evaluation)
        let B = buildB(evaluation[0], evaluation[1], mesh.xLength, mesh.yLength, center);
        let w = evaluation[3];
        let stiffness = math.multiply(w, math.multiply(math.multiply(math.transpose(B), C), B))
        elementStiffness = math.add(elementStiffness, stiffness);
    }
    let K = elementStiffness.toArray();
    if (!storeRepeatedStiffness && !element.outside) storeRepeatedStiffness = K;
    return K;
}



const stepBoundryPoints = gaussQ(2);

let evaulateElementBC = function (mesh, element, partData, BCs, C) {

    if (element.outside != true || !element.edgeLines) return;
    let elementLoad = new Array(8).fill(0);
    let edgesInElement = element.edgeLines.map(x => x.id)
    let N_func = buildNFunc2D(mesh, element);

    for (let BC of BCs) {

        let edgeIndex = edgesInElement.indexOf(BC.edgeId);
        if (edgeIndex === -1) continue;
        let edgeLine = element.edgeLines[edgeIndex];

        if (BC.type == "traction") {
            //collect all intergration points;
            let toEval = [];
            for (let segment of edgeLine.segments) {
                let result = intergrateLine(segment)
                toEval.push(...result.points);
            }

            //evaluate traction at all points: [N]{t}
            let T = [Number(BC.value.x), Number(BC.value.y)];
            for (let point of toEval) {
                let w = point.weight;
                let pos = point.position; //in global coordinates
                let N_T = math.transpose(N_func(pos[0], pos[1]));
                let pointLoad = math.multiply(N_T, T).toArray();
                elementLoad = elementLoad.map((x, i) => x += (w * pointLoad[i]));
            }

        } else if (BC.type == "fixed") {
            //handle stiffness intergration 
            //collect all intergration points;
            let toEval = [];
            for (let segment of edgeLine.segments) {
                let result = intergrateLine(segment); //get intergration points along segment
                for (let point of result.points) {
                    let w = point.weight;
                    let pos = point.position; //in global coordinates

                    //compute and normalize normal
                    let vector = [segment[1][0] - segment[0][0], segment[1][1] - segment[0][1], 0];
                    let zDir = [0, 0, 1];
                    let normal = crossProduct(zDir, vector);
                    let normalMag = Math.sqrt(normal[0] * normal[0] + normal[1] * normal[1]);
                    normal = normal.map(x => x /= normalMag);
                    normal[2] = 0;

                    let pos2 = [pos[0] - normal[0] * δ, pos[1] - normal[1] * δ]; //normal points out. We want to go into body thus (-)
                    let phiLine = [pos, pos2];

                    let { points } = intergrateLine(phiLine, stepBoundryPoints) //handle intergration across step boundry
                    for (let point of points) {
                        let w2 = point.weight;
                        let pos2 = point.position; //in global coordinates
                        let B_bar = build_B_bar(mesh, element, pos, [BC.value.x, BC.value.y], pos2[0], pos2[1], normal)
                        let stiffness = math.multiply(w * w2, math.multiply(math.multiply(math.transpose(B_bar), C), B_bar))

                        for (let i = 0; i < 8; i++) {
                            for (let j = 0; j < 8; j++) {
                                element.stiffness[i][j] += stiffness[i][j]
                            }
                        }
                    }
                }
            }
        }
    }
    return elementLoad;
}

let crossProduct = function ([a1, a2, a3], [b1, b2, b3]) {
    return [a2 * b3 - a3 * b2, a3 * b1 - a1 * b3, a1 * b2 - a2 * b1]
}


let getSideNodes = function (nodes, x_value, y_value) {
    let sideNodes = [];
    let term = x_value;
    if (y_value != undefined) term = y_value;
    for (let i in nodes) {
        //if(nodes[i].isInPart == false) continue;
        let toCheck = nodes[i].orgPos[0];
        if (y_value != undefined) toCheck = nodes[i].orgPos[1];
        if (check(toCheck, term))
            sideNodes.push(Number(i))
    }
    return sideNodes;
}

let getSideElements = function (elements, nodes, x_value, y_value) {
    let sideElements = [];
    let term = x_value;
    if (y_value != undefined) term = y_value;
    for (let i in elements) {
        let element = elements[i];
        let inside = 0;
        for (let node of element.nodes) {
            let toCheck = nodes[node].orgPos[0];
            if (y_value != undefined) toCheck = nodes[node].orgPos[1];
            if (check(toCheck, term)) {
                inside++;
            }
        }
        if (inside > 0) sideElements.push(element);
    }

    return sideElements;
}

let getNodesFromElements = function (elementList, nodeLength) {
    let nodeList = new Array(nodeLength).fill(false);
    for (let element in elementList) {
        for (let node of elementList[element].nodes) {
            nodeList[node] = true;
        }
    }
    let list = [];
    for (let i in nodeList) { if (nodeList[i]) list.push(Number(i)); };
    list.sort(function (a, b) {
        return a - b;
    });
    return list;
}

let find = function (array, value) {
    for (let i = 0; i < array.length; i++) {
        if (array[i] == value) return i;
    }
    return null;
}

let remoteSolverWorking = false;
let checkRemoteSolverStatus = async () => {
    try {
        let data = (await axios.get('http://localhost:3000/status', { timeout: 100 })).data; //check if remote solver is working. timeout is to prevent blocking. should be ~2ms res time
        if (data.includes("ready")) remoteSolverWorking = true;
    } catch (e) {

    }
}
//heckRemoteSolverStatus();


let assembleElements = async function (mesh, partData, C) { //C = consitutive matrix

    setStatus("Assembling Global Stiffness Matrix");


    let isNodeUsed = new Array(mesh.nodes.length).fill(false);
    let reducedMapping = new Array(mesh.nodes.length).fill(-1);

    let toStrike = []; //extra nodes to be removed

    for (let element of mesh.elements) {
        for (let node of element.nodes) {
            if (element.areaRatio > 0.0005 && toStrike.includes(node) == false) { //this removes elements that are too small, and if the node is alone it will be striked from [K_global]
                isNodeUsed[node] = true;
            }
        }
    };


    let count = 0;
    for (let i in isNodeUsed) {
        if (isNodeUsed[i]) {
            reducedMapping[i] = count;
            count++;
        }
    }
    let n = count * 2;

    console.time("Global Stiffness assembly");

    let globalDiagonals = [];

    let globalLoadVector = new Array(n).fill(0);

    const sparseMap = new Map();
    let maxDiagonal = 0;
    for (let element of mesh.elements) {
        let globalNodeIndexs = [];
        for (let node of element.nodes) {
            let nodeMapped = reducedMapping[node];
            globalNodeIndexs.push(nodeMapped * 2, nodeMapped * 2 + 1);
        }
        for (let i = 0; i < 8; i++) {
            let r = globalNodeIndexs[i]
            if (r < 0) continue;
            for (let j = 0; j < 8; j++) {
                let c = globalNodeIndexs[j];
                if (c < 0) continue;
                let prev = 0;
                let id = r + "," + c;
                if (sparseMap.has(id)) prev = sparseMap.get(id);
                let res = prev + element.stiffness[i][j];
                sparseMap.set(id, res);
            };
            //handle building load vector
            if (element.load) globalLoadVector[Number(r)] += element.load[i];
        }
    };



    console.timeEnd("Global Stiffness assembly");
    let leftElements = getSideElements(mesh.elements, mesh.nodes, mesh.nodes[0].orgPos[0]);
    let leftSide = getNodesFromElements(leftElements, mesh.nodes.length).filter(x => reducedMapping[x] != -1).map(x => 2 * reducedMapping[x] + 1);
    console.log("leftSide.length = ", leftSide.length)


    setStatus("Setting up the sparse solver...")

    let solverParams = {
        columns: [],
        rows: [],
        data: [],
        lhs: []
    }
    let items = [];;
    sparseMap.forEach((value, key) => {
        let [row, col] = key.split(",").map(Number);
        items.push(row, col, value)
        if (row < col || row == col) {
            solverParams.columns.push(col);
            solverParams.rows.push(row);
            solverParams.data.push(value);
        }
    });

    let areaCutOff = 0.3;
    let output = [];

    console.log("Starting Solve... n=", n);
    if (n < 15000 || remoteSolverWorking == false) {
        if (n > 95000) throw "Too Large";
        output = numericSolver(items, globalLoadVector, n);//blows mathjs out of the water
    } else {
        solverParams.lhs = globalLoadVector;
        solverParams.count = sparseMap.size;
        output = await remoteSolver(solverParams);
    }

    setStatus("A*x=b solver complete.")


    let nodeDisplacments = lodash.chunk(output, 2);

    let nodeIndexMapping = new Array(nodeDisplacments.length).fill(-1);
    for (let i in reducedMapping) {
        let val = reducedMapping[i];
        nodeIndexMapping[val] = Number(i);
    }
    let maxDisp = 0, minDisp = 1e20;
    for (let i in nodeDisplacments) {
        let nodeIndex = nodeIndexMapping[i];
        let dispMag = Math.sqrt(nodeDisplacments[i][0] * nodeDisplacments[i][0] + nodeDisplacments[i][1] * nodeDisplacments[i][1]);
        mesh.nodes[nodeIndex].disp = nodeDisplacments[i];
        mesh.nodes[nodeIndex].value = dispMag;
        if (maxDisp < dispMag) maxDisp = dispMag;
        if (minDisp > dispMag) minDisp = dispMag;
    }
    for (let node of mesh.nodes) if (!node.disp) node.disp = [0, 0];
    


    console.time("Computing Stresses")
    setStatus("Computing Nodal Stresses...");

    let vonMisesDataPoints = [];
    for (let element of mesh.elements) {
        let q = [];
        for (let nodeIndex of element.nodes) {
            let node = mesh.nodes[nodeIndex];
            q.push(node.disp[0], node.disp[1]);
        }
        element.q = q;
        let stress_func = getElementStressFunction(mesh, C, element);
        element.vonStress = [];
        for (let nodeIndex of element.nodes) {
            let node = mesh.nodes[nodeIndex];
            let stress = stress_func(node.orgPos[0], node.orgPos[1]);
            let vonMisesValue = getVonMises(stress);
            if (!mesh.nodes[nodeIndex].stressValues) mesh.nodes[nodeIndex].stressValues = [];
            if (element.areaRatio > areaCutOff) mesh.nodes[nodeIndex].stressValues.push(vonMisesValue);


            vonMisesDataPoints.push(vonMisesValue);
            element.vonStress.push(vonMisesValue);
        }
        let N_func = buildNFunc(mesh, element);
        element.stressForEachTriangleNode = [];
        element.trangulations.map(triangle => {
            triangle.map(point => {
                let stress;
                stress = getVonMises(stress_func(point[0], point[1]));
                element.stressForEachTriangleNode.push(stress)
            })
        })
    }

    let i = 0;
    for (let node of mesh.nodes) {
        if (isNodeUsed[i++] == false) {
            node.von = 0;
            continue;
        };
        let avgStress = 0;
        for (let stress of node.stressValues) avgStress += stress;
        avgStress = avgStress / node.stressValues.length;
        node.von = avgStress || 0;
    }

    vonMisesDataPoints = filterOutliers(vonMisesDataPoints);

    console.timeEnd("Computing Stresses")

    let minVonMises = arrayMin(vonMisesDataPoints);
    let maxVonMises = arrayMax(vonMisesDataPoints);
    mesh.results = {
        maxDisp, minDisp, minVonMises, maxVonMises
    }
    await saveResults(mesh); //save results to browser storage
    return { displacement: output, maxDisp, minDisp, minVonMises, maxVonMises, mesh };
}





//solver options
let remoteSolver = async function (solverParams) {
    setStatus(`Solving A*x = b (n=${solverParams.lhs.length}) using remote solver...`);
    console.time("Remote Solver");
    let data = (await axios.post('http://localhost:3000/solver', solverParams)).data;
    console.timeEnd("Remote Solver");
    return data;
}



let mathJsSolver = function (items, b, n) {
    setStatus(`Solving A*x = b (n=${n}) using math.js...`);
    console.time("MathJs Solver");
    let MK = math.sparse(); //using MathJs as crutch
    MK.resize([n, n]);
    let csc = (new COO(n, n, items)).toCSC();
    MK._ptr = csc.cols; //directly insert into math.js sparse matrix
    MK._values = csc.data;
    MK._index = csc.rows;

    let out = math.lusolve(MK, b).toArray().map(x => x[0])
    console.timeEnd("MathJs Solver");
    return out;
}


let numericSolver = function (items, b, n) {
    setStatus(`Solving A*x = b (n=${n}) using numeric.js...`);
    console.time("Numeric.js Solver");

    let csc = (new COO(n, n, items)).toCSC();
    let LU = numeric.ccsLUP([csc.cols, csc.rows, csc.data]);
    let sol = numeric.ccsLUPSolve(LU, b);

    console.timeEnd("Numeric.js Solver");
    return sol;

}


function filterOutliers(someArray) {

    // Copy the values, rather than operating on references to existing values
    var values = someArray.concat();

    // Then sort
    values.sort(function (a, b) {
        return a - b;
    });

    /* Then find a generous IQR. This is generous because if (values.length / 4) 
     * is not an int, then really you should average the two elements on either 
     * side to find q1.
     */
    var q1 = values[Math.floor((values.length / 4))];
    // Likewise for q3. 
    var q3 = values[Math.ceil((values.length * (3 / 4)))];
    var iqr = q3 - q1;

    // Then find min and max values
    var maxValue = q3 + iqr * 1.5;
    var minValue = q1 - iqr * 1.5;

    // Then filter anything beyond or beneath these values.
    var filteredValues = values.filter(function (x) {
        return (x <= maxValue) && (x >= minValue);
    });

    // Then return
    return filteredValues;
}


function arrayMin(arr) {
    var len = arr.length, min = Infinity;
    while (len--) {
        if (arr[len] < min) {
            min = arr[len];
        }
    }
    return min;
};

function arrayMax(arr) {
    var len = arr.length, max = -Infinity;
    while (len--) {
        if (arr[len] > max) {
            max = arr[len];
        }
    }
    return max;
};



let getDisplacedGraphics = function (mesh, partData, scaler = 10000, outsideOnly = false, extremes = null, showStress = false, smoothed = false) {
    setStatus("Computing displaced graphics...");
    let allTrangulations = [];
    let colors = [];
    let lut = new Lut('rainbow', 512);

    if (extremes) {
        lut.setMin(extremes[0]);
        lut.setMax(extremes[1]);
    }
    for (let element of mesh.elements) {
        if (outsideOnly === true && element.outside != true) continue;
        let N_func = buildNFunc(mesh, element);
        if (!mesh.nodes[0].disp) continue;
        let q_u = element.nodes.map(x => mesh.nodes[x].disp[0]);
        let q_v = element.nodes.map(x => mesh.nodes[x].disp[1]);
        let i = 0;
        let elementAvgStresses;
        if (smoothed) elementAvgStresses = element.nodes.map(x => mesh.nodes[x].von);
        let elementTrang = element.trangulations.map(points => {
            return points.map(point => {
                let N = N_func(point[0], point[1]);
                let u = math.multiply(N, q_u);
                let v = math.multiply(N, q_v);
                let c;
                if (smoothed) {
                    let s = math.multiply(N, elementAvgStresses);
                    c = lut.getColor(s);
                } else if (showStress) {
                    c = lut.getColor(element.stressForEachTriangleNode[i]);
                } else {
                    let dispMag = math.sqrt(u * u + v * v);
                    c = lut.getColor(dispMag); //show displacement magnitude
                }

                if (extremes && c) colors.push(255 * c.r, 255 * c.g, 255 * c.b);
                i++;
                return [point[0] + u * scaler, point[1] + v * scaler];
            })
        });
        allTrangulations.push(...elementTrang);
    }
    colors = colors.map(Math.ceil)
    return { allTrangulations, colors, rawTrangles: convertToTriVerts(allTrangulations) };
}


let cleanMesh = function (mesh) {
    for (let element of mesh.elements) {
        delete element.stiffness;
        delete element.load;
        delete element.edgeLines;
        delete element.evaulations;
    }
}

let saveResults = async function (mesh, displacement) {
    cleanMesh(mesh); //remove lots of data from mesh elements
    await localforage.setItem("results", JSON.stringify(mesh));
    console.log("results saved");
}

let convertToTriVerts = function (trangulations, zOffset = 0) {
    let verts = [];
    let i = 0;
    for (let tri of trangulations) {
        for (let vert of tri) {
            verts[3 * i] = vert[0];
            verts[3 * i + 1] = vert[1];
            verts[3 * i + 2] = zOffset;
            i++;
        }
    }
    return verts;
}

let getBounds = function (vertices) {
    let mins = [1e10, 1e10, 1e10];
    let maxs = [-1e10, -1e10, -1e10];
    for (let point of vertices) {
        for (let i in point) {
            let val = point[i];
            if (val < mins[i]) mins[i] = val;
            if (val > maxs[i]) maxs[i] = val;
        }
    }
    mins[2] = 0;
    maxs[2] = 0;
    if (mins[0] == maxs[0]) maxs[0] += Math.abs(maxs[0]) * 0.0001;
    if (mins[1] == maxs[1]) maxs[1] += Math.abs(maxs[1]) * 0.0001;

    return { maxs, mins };
}

let checkInBounds = function (bounds, point, expandFactor = 10) {
    let inBounds = 0;
    for (let i in point) {
        if (i == 2) continue;
        if (point[i] > bounds.mins[i] * expandFactor && point[i] < bounds.maxs[i] * expandFactor) inBounds++;
    }
    if (inBounds == 2) return true;
    return false;
}

let getElementBounds = function (mesh, element) {
    let positions = element.nodes.map(x => mesh.nodes[x].orgPos);
    let elementBounds = getBounds(positions);
    return elementBounds
}

let checkIfTwoBoundsIntersect = function (bounds1, bounds2) {
    if (bounds1.maxs[0] < bounds2.mins[0]) return false;
    if (bounds1.mins[0] > bounds2.maxs[0]) return false;
    if (bounds1.maxs[1] < bounds2.mins[1]) return false;
    if (bounds1.mins[1] > bounds2.maxs[1]) return false;
    return true;
}


let combineEdges = function (elements) {
    let combined = []
    for (let element of elements) {
        for (let edge of element.edgeLines) {
            combined = [...combined, ...edge.segments];
        }
    }
    return combined;
}


let getEdgesForEachElement = function (part, mesh) {
    console.log("Getting edges for each element...");
    let partData = part.partData;
    let edges = partData.edges;
    let elements = mesh.elements;
    let edgeMap = new Map();

    for (let edge of edges) {
        edgeMap.set(edge.id, edge.vertices);
        edge.boundingBox = getBounds(edge.vertices);
    }

    let outsideElements = 0;
    let elementsWithEdges = 0;
    for (let element of elements) {
        if (element.outside != true) continue;
        outsideElements++;
        element.centroid = getElementCenter(mesh, element);
        element.edges = [];
        let segmentIntersects = [];
        let boundingBox = getElementBounds(mesh, element);
        for (let edge of edges) {
            let edgeActive = false;
            let segments = [];
            let vertices = edge.vertices;
            for (let i in vertices) {
                if (i == 0) continue;
                let edgeSegmentBounds = getBounds([vertices[i - 1], vertices[i]]);
                let intersection = checkIfTwoBoundsIntersect(edgeSegmentBounds, boundingBox);
                if (intersection) {
                    edgeActive = true;
                    segments.push(Number(i - 1));
                }

            }
            if (edgeActive) {
                element.edges.push({ id: edge.id, segments });
            }
        }
        if (element.edges.length > 0) {

            elementsWithEdges++;

        } else {
            element.majorIssue = true;

        }
        element.segmentIntersects = segmentIntersects;
    }
    if (elementsWithEdges != outsideElements) {
        //alert("An edge segment was not found for all outside elements")
        console.error("An edge segment was not found for all outside elements");
    }

    for (let element of elements) {
        let boundingBox = getElementBounds(mesh, element);
        let segCount = 0;
        element.edgeLines = [];
        if (element.outside != true) continue;
        for (let edge of element.edges) {
            let vertices = edgeMap.get(edge.id);
            let segmentPoints = [];
            let segmentIsContained = [];
            let intPoints = [];
            let elementEdgeSegements = [];
            for (let i in vertices) {
                if (i == 0) continue;
                if (edge.segments.includes(i - 1)) {
                    segmentPoints.push([vertices[i - 1], vertices[i]]);

                    let v_i_1 = [vertices[i - 1][0], vertices[i - 1][1]]; //point1
                    let v_i = [vertices[i][0], vertices[i][1]]; //point2

                    if (check(vertices[i - 1][0], vertices[i][0]) && check(vertices[i - 1][1], vertices[i][1])) {
                        //console.log("Points overlap!")
                        continue;
                    }
                    let point1Inside = checkInBounds(boundingBox, vertices[i - 1], 1)
                    let point2Inside = checkInBounds(boundingBox, vertices[i], 1)
                    if (point1Inside == false || point2Inside == false) {
                        let intesectionPoints = getElementIntersectionsWithLine(mesh, element, [vertices[i - 1], vertices[i]]);
                        intPoints.push(intesectionPoints)
                        if (point1Inside && !point2Inside) { //point 1 is inside, point 2 is outside
                            if (intesectionPoints.length != 1) {
                                console.error("More/Less than one intersection point found", intesectionPoints);
                                element.majorIssue = true;
                                if (intesectionPoints.length == 0) continue;
                            }
                            let localLine = [v_i_1, intesectionPoints[0]];
                            elementEdgeSegements.push(localLine)
                        } else if (!point1Inside && point2Inside) { //point 1 is outside, point 2 is inside
                            if (intesectionPoints.length != 1) {
                                console.error("More/Less than one intersection point found", intesectionPoints);
                                element.majorIssue = true;
                                if (intesectionPoints.length == 0) continue;
                            }
                            elementEdgeSegements.push([intesectionPoints[0], v_i])

                        } else if (!point1Inside && !point2Inside) { //both points are outside
                            if (intesectionPoints.length == 0) {
                                console.error("Could not find intersection point")
                                element.majorIssue = true;
                                element.issue = true;
                                continue
                            };
                            if (intesectionPoints.length != 2) {
                                throw ("More/Less than 2 intersection point found");
                            }
                            let disp1 = getDisplacement(v_i_1, intesectionPoints[0]);
                            let disp2 = getDisplacement(v_i_1, intesectionPoints[1]);
                            if (disp1 < disp2) {
                                elementEdgeSegements.push([intesectionPoints[0], intesectionPoints[1]])
                            } else {
                                elementEdgeSegements.push([intesectionPoints[1], intesectionPoints[0]])
                            }
                        }
                    } else { //both inside
                        elementEdgeSegements.push([v_i_1, v_i])
                    }
                }
            }
            element.edgeLines.push({ id: edge.id, segments: elementEdgeSegements.map(x => x.map(j => [j[0], j[1], 0])) });
        }
    }

    return elements;
}


let getDisplacement = (A, B) => {
    let delta = [A[0] - B[0], A[1] - B[1]];
    return Math.sqrt(delta[0] * delta[0] + delta[1] * delta[1]);
}
let getVector = (A, B) => [A[0] - B[0], A[1] - B[1]];
let dotProduct = (A, B) => (A[0] * B[0] + A[1] * B[1]);


//step boundry methods
let δ = 1.0e-9; //step value thickness
let H_func = (φ) => {  //H function - Step Boundary Method - https://doi.org/10.1115/1.4045054
    if (φ >= δ) return 1;
    if (0 <= φ <= δ) return (φ / δ) * (2 - φ / δ);
    return 0;
}
let H_Matrix = (φ) => { //global coord system   [I] -> i==j is H_func(φ)
    let H = [
        [H_func(φ), 0],
        [0, H_func(φ)]
    ];
    return H;
}

let dH_dφ = (φ) => {
    if (φ >= δ) return 0;
    if (0 <= φ && φ <= δ) return 2 * (δ - φ) / (δ * δ); //big number since δ is small
    return 0;
}

let getφ = (px, py, x, y) => Math.sqrt((px - x) * (px - x) + (py - y) * (py - y));

let build_B_bar = (mesh, element, [px, py], [fixX = true, fixY = true], x, y, normal) => {

    let center = getElementCenter(mesh, element)
    let width = mesh.xLength;
    let height = mesh.yLength;
    let b = width / 2;
    let h = height / 2;
    let xc = x - center[0];
    let yc = y - center[1];

    let k = 1 / (4 * b * h);
    let N = [k * (b - xc) * (h - yc), k * (b + xc) * (h - yc), k * (b + xc) * (h + yc), k * (b - xc) * (h + yc)]; //shape functions
    let N_x = [-k * (h - yc), k * (h - yc), k * (h + yc), -k * (h + yc)]; //derivative of N with respect to x
    let N_y = [-k * (b - xc), -k * (b + xc), k * (b + xc), k * (b - xc)]; //derivative of N with respect to y

    let φ = getφ(px, py, x, y); //where px,py is the closest edge point. Get distance to x,y
    let h1 = H_func(φ)
    let h2 = h1;

    let dφ_dx = normal[0]
    let dφ_dy = normal[1]

    let H_x = [], H_y = [];
    H_x[0] = dH_dφ(φ) * dφ_dx;
    H_x[1] = dH_dφ(φ) * dφ_dx;
    H_y[0] = dH_dφ(φ) * dφ_dy;
    H_y[1] = dH_dφ(φ) * dφ_dy;

    if (fixX == false) {  //fix up to one of two directions
        H_x[0] = 0;
        H_y[0] = 0;
        h1 = 0;
    } else if (fixY == false) {
        H_x[1] = 0;
        H_y[1] = 0;
        h2 = 0;
    }

    //big matrix B_bar. Consider theory ref.
    return [
        [H_x[0] * N[0] + h1 * N_x[0], 0, H_x[0] * N[1] + h1 * N_x[1], 0, H_x[0] * N[2] + h1 * N_x[2], 0, H_x[0] * N[3] + h1 * N_x[3], 0],
        [0, H_y[1] * N[0] + h2 * N_y[0], 0, H_y[1] * N[1] + h2 * N_y[1], 0, H_y[1] * N[2] + h2 * N_y[2], 0, H_y[1] * N[3] + h2 * N_y[3]],
        [H_y[0] * N[0] + h1 * N_y[0], H_x[1] * N[0] + h2 * N_x[0], H_y[0] * N[1] + h1 * N_x[1], H_x[1] * N[1] + h2 * N_x[1], H_y[0] * N[2] + h1 * N_y[2], H_x[1] * N[2] + h2 * N_x[2], H_y[0] * N[3] + h1 * N_y[3], H_x[1] * N[3] + h2 * N_x[3]],
    ]
}


const lineGaussPoints = gaussQ(2);
let intergrateLine = function (line, GPoints = lineGaussPoints) {  //https://en.wikipedia.org/wiki/Gaussian_quadrature
    let a = 0, b = 1;
    let p1 = line[0];
    let p2 = line[1];
    let vector = [p2[0] - p1[0], p2[1] - p1[1]];
    let GaussPoints = GPoints[0];
    let weights = GPoints[1];
    let lineLength = Math.sqrt(vector[0] * vector[0] + vector[1] * vector[1]);
    let dx_dξ = lineLength * (b - a) / 2;  //(dx/ds)(ds/dξ)
    let gMapping = (ξ) => 0.5 * (b - a) * ξ + 0.5 * (b + a);
    let paramertricMapping = (s) => [p1[0] + vector[0] * s, p1[1] + vector[1] * s];
    let intergrationPoints = [];
    for (let i in GaussPoints) {
        let point = GaussPoints[i];
        let s = gMapping(point); //convert from [-1,1] to [a,b]
        let pos = paramertricMapping(s); //convert from (s) to (x,y)
        let w = weights[i] * dx_dξ; //account for the weight and convert to global scale
        intergrationPoints.push({ position: pos, weight: w });
    }
    return { points: intergrationPoints, length: lineLength };
}


export default {
    createMesh,
    removeElements,
    getElementIntersectionsRegion,
    makePolygonFromTriangles,
    getPolygonRegion,
    hook,
    getDisplacedGraphics,
    convertToTriVerts,
    combineEdges
}
