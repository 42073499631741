const math = require("mathjs")
const MatrixMultiply = (a,b) => a.map(c=>b[0].map((_,i)=>b.reduce((s,d,j)=>s+d[i]*c[j],0)))
const transpose = m => math.transpose(m)
const scale2D = (k,A) => A.map(B => B.map(x=>x*k) )
const sum2D = (A,B) => A.map((x,i)=>x.map((n,j) => n+B[i][j]))
const sum = (A,B) => A.map((x,i)=>x+B[i]);
const zeros2D = (rows,columns) => Array(rows).fill().map(() => Array(columns || rows).fill(0));
const round2D = (A, place) => A.map(B => B.map(x=>(x) ))
const strikeMatrix = function(A, indexToStrike){ //reduce matrix by index list
    let size = A.length-indexToStrike.length;
    let iSkips = 0;
    let jSkips = 0;
    let smallerMatrix = [];
    for(let i = 0; i<A.length; i++){
        if(indexToStrike.includes(i)){
            iSkips++; 
            continue; 
            
        }
        let row = [];
        for(let j = 0; j<A.length; j++){
            if(indexToStrike.includes(j)){
                jSkips++; continue; 
            }
            row.push(A[i][j])
        }
        smallerMatrix.push(row);
    }
    return smallerMatrix;
};


const checkMatrixSym = (A) => {
    let max = 0;
    let diff = sum2D(transpose(A), scale2D(-1, A));
    diff.map(row=>row.map(x=>{
        let val = Math.abs(x);
        if(val > max) max = val;
    }));
    return max;
}

let matlabOut2d = A => {
    let str = "A = [\n";
    for(let row of A){
        str += "["
        str += row.join(',');
        str += "], \n"
    }
    str += "];";
    console.log(str);
}

const getDeterminate = m => 
  m.length == 1 ?
  m[0][0] :
  m.length == 2 ? 
  m[0][0]*m[1][1]-m[0][1]*m[1][0] :
  m[0].reduce((r,e,i) => 
    r+(-1)**(i+2)*e*determinant(m.slice(1).map(c => 
      c.filter((_,j) => i != j))),0)



module.exports = {matlabOut2d, checkMatrixSym, strikeMatrix, MatrixMultiply, transpose, scale2D, sum,  sum2D, zeros2D, round2D, getDeterminate}