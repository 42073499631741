<template>
  <div class="container pt-5 pb-5">
    <div v-if="!loading">

      <div v-if="uploadedDoc == null">
        <h3>Save Your Analysis</h3>
        <div class="form-group">
          <label>Analysis Name</label>
          <input
            type="text"
            v-model="name"
            class="form-control"
            placeholder="My amazing model"
          />
          <br />
          <label>Analysis Preview Image</label>
          <Cropper
            class="preview"
            ref="cropper"
            :stencil-props="{
              aspectRatio: 1 / 1,
            }"
            v-if="imageData"
            :src="imageData"
            alt=""
          ></Cropper>
        </div>
        <button
          type="submit"
          :disabled="!name"
          class="btn btn-primary mt-2"
          @click="saveModel()"
        >
          Save & Upload Model
        </button>
      </div>

      <div v-if="uploadedDoc != null">
        <h3>Document uploaded</h3>

        <label>Details:</label>
        <table class="table">
          <tbody>
            <tr class="table-active">
              <th scope="row">Name</th>
              <td colspan="2" class="table-active">{{uploadedDoc.name}}</td>
            </tr>
            <tr>
              <th scope="row">Size</th>
              <td colspan="2" class="table-active">{{uploadedDoc.size}}</td>
            </tr>
            <tr>
              <th scope="row">ID</th>
              <td colspan="2" class="table-active">{{uploadedDoc.id}}</td>
            </tr>
              <tr>
              <th scope="row">_id</th>
              <td colspan="2" class="table-active">{{uploadedDoc._id}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <Texture>
        <progress id="file" :value="progress" max="100">
          {{ progress }}%</progress
        ><br />Uploading Document To Cloud</Texture
      >
    </div>
  </div>
</template>

<script>
import Texture from "../loading.vue";
import talk from "./../../talk.js";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import localforage from "localforage";

export default {
  components: { Texture, Cropper },

  data() {
    let imageData = localStorage.getItem("partImage");

    return {
      documents: [],
      name: "",
      imageData,
      loading: false,
      progress: 0,
      uploadedDoc: null,
    };
  },
  methods: {
    async saveModel() {
      this.loading = true;

      let name = this.name;

      const { coordinates, image, visibleArea, canvas } =
        this.$refs.cropper.getResult();

      let mesh = localStorage.getItem("mesh");
      let part = localStorage.getItem("test-geometry");
      let bc = localStorage.getItem("BC");
      let results = await localforage.getItem("results");
      let CMat = await localforage.getItem("CMat");
      let analysis = await localforage.getItem("analysis");
      if (!mesh || !part || !bc) return null;

      let version = 1;
      let document = { mesh, part, bc, results, CMat, analysis, v: version };
      let payload = { name, v: version };
      let self = this;

      this.uploadedDoc = await talk.uploadModel(
        payload,
        document,
        canvas.toDataURL(),
        function (progress) {
          self.progress = progress; //hook into upload progress
        }
      );
      this.loading = false;
    },
  },
  async mounted() {},
};
</script>


<style scoped lang="scss">

.container{
    max-width: 680px;
    border: 1px solid black;
    padding: 20px 20px !important;
    margin-top: 20px;
}

</style>