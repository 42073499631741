<template>
    <div>
        <Nav></Nav>
        <div style="min-height: 80vh"><router-view></router-view></div>
        <div class="cc">© 2021-{{year}} <a href="https://johndews.com">John Dews-Flick</a></div>
    </div>
</template>


<script>

import Nav from './nav.vue'


export default {
    components: {Nav},
    data() {
        return {
            year: new Date().getFullYear()
        }
    },
    mounted(){
    
    }
};
</script>

<style scoped>
.cc {
    width: 100%;
    text-align: center;
    background-color: #fafafa;
    padding: 10px;
    position: fixed;
    bottom: 0;
}
</style>
