<template>
  <div class="container" style="max-width: 650px">
    <div v-if="user">
      <div class="card mt-5">
        <div class="card-body">
          <h4 class="center">Welcome {{ user.name }}</h4>

          <p class="center">Onshape Account Connected: {{ user.email }}</p>

          <div class="center">
            <router-link to="/import">
              <button type="button" class="btn btn-primary">
                Import From OnShape
              </button>
            </router-link>
             
            <router-link to="/ibm/list" class="btn btn-primary">Import an Example</router-link>
<router-link to="/logout"
            ><button type="button" class="btn btn-outline-info">
              Logout
            </button></router-link>
          </div>

        
         



        </div>
      </div>
    </div>
    <About></About>
  </div>
</template>

<script>
import onShapeOAuth from "./OnShapeOAuthRequest.js";
import About from './../about.vue'

export default {
  components: {About},
  data() {
    return {
      user: null,
    };
  },
  async mounted() {
    this.user = await onShapeOAuth.saveUserDetails();
  },
};
</script>

<style>
</style>