import axios from "axios";

const api = "https://web_sim_api.johndews.workers.dev/os/api/"
const tokenStore = null;

let get = async function(url, urlParams, token){
    if(!token) token = tokenStore || localStorage.getItem("token");
    if(!token) throw "No Token";
    let req = await axios.get(api+url, {
        headers: { 'Auth': token },
        params: urlParams
    });
    console.log(urlParams, req.data)
    return req.data;
}


let getDocuments = async function(){
    let documents = await get("documents");
    return documents;
}

let getDocument = async function(id){
    let doc = await get(`documents/${id}`);
    let wID = doc.defaultWorkspace.id;
    doc.parts = await get(`parts/d/${id}/w/${wID}`,{ withThumbnails: true })
    return doc;
}

let getDocumentThumbnail = function(documentId, workspaceId){
    return `https://cad.onshape.com/api/thumbnails/d/${documentId}/w/${workspaceId}/s/300x170`
}

let getDocumentsFull = async function(){
    let docs = await get("documents");
    let tasks = [];

    console.log(docs);
    docs.items = [docs.items[0]]
    console.time("Finished Getting "+docs.items.length +" Documents w/ Parts");
    for(let doc of docs.items){
        let id = doc.id;
        let elementId = doc.defaultElementId;
        tasks.push(getDocument(id))
    }
    tasks = await Promise.all(tasks);
    console.timeEnd("Finished Getting "+docs.items.length +" Documents w/ Parts");
    return tasks;
}


let getParts = async function(document, workspace){
    let parts = await get(`parts/d/${document}/w/${workspace}`,{ withThumbnails: true });
    return parts;
}

let getPartData = async function(docID, workID, eID, partID){
    let tasks = [];
    let params = {};
    //params = {chordTolerance: 0.0001, angleTolerance:0.025}
    console.time("Finished Getting Part Data with Tessellations")
    tasks.push(get(`parts/d/${docID}/w/${workID}/e/${eID}/partid/${partID}/tessellatededges`, params))
    tasks.push(get(`parts/d/${docID}/w/${workID}/e/${eID}/partid/${partID}/tessellatedfaces`, params))
    tasks.push(get(`parts/d/${docID}/w/${workID}/e/${eID}/partid/${partID}/bodydetails`))
    let [edges, faces, details] = await Promise.all(tasks);
    edges = edges[0].edges;
    faces = faces[0].faces;
    details = details.bodies[0];
    console.timeEnd("Finished Getting Part Data with Tessellations")
    return {edges, faces, details};
};

let reduceToPlane = function(partData, zPlane){


}

let getAllTriangles = function(partData){
    let triangles = [];
    for(let face of partData.faces){
        for(let facet of face.facets){
            triangles.push(facet.vertices);
        }
    }
    return triangles;
}

let getExtremes = function(triangles){
    let a = 1e20;
    let maxs = [-a,-a,-a];
    let mins = [a,a,a];
    for(let tri of triangles) {
        for(let coords of tri){
            for(let i in maxs){
                if(coords[i] > maxs[i] ) maxs[i]=coords[i];
                if(coords[i] < mins[i] ) mins[i]=coords[i];
            }
        }
    }

    return {
        maxs,
        mins
    }

}

let check = function(value, target){
    if(!target) target = 0.0;
    return Math.abs(target-value) < 0.001;
}

let reduceToZPlane = function(triangles){
    return triangles.filter(tri => {
        if(check(tri[0][2]) && check(tri[1][2]) && check(tri[2][2]) ) return true;
        return false;
    })
}

let reduceDataToZPlane = function(partData){
    let validEdgeIds = [];
    partData.edges = partData.edges.filter(edge => {
        let failed = false;
        for(let vertex of edge.vertices){
            if(!check(vertex[2])){
                failed = true;
                break;
            }
        }
        if(failed == false) validEdgeIds.push(edge.id);
        return !failed;
    });
    let validFaceIds = [];
    partData.faces = partData.faces.filter(face => {
        let failed = false;
        for(let facet of face.facets){
            for(let vertex of facet.vertices){
                if(!check(vertex[2])){
                    failed = true;
                    break;
                }
            }
        }
        if(failed == false) validFaceIds.push(face.id);
        return !failed;
    });
    partData.details.faces= partData.details.faces.filter(face => { return validFaceIds.includes(face.id) })
    partData.details.edges= partData.details.edges.filter(edge => { return validEdgeIds.includes(edge.id) })
    if(partData.details.faces.length != 1) throw "For a 2D part, there should be only one face"

    //fix so that edge go in correct direction
    let reverseEdgeIds = new Map();
    let coEdges = [];
    for(let loop of partData.details.faces[0].loops){
        console.log(loop)
        for(let coEdge of loop.coedges){
            reverseEdgeIds.set(coEdge.edgeId, coEdge.orientation);
        }
        coEdges.push(...loop.coedges);
    }
    partData.edges = partData.edges.map(edge => {
        let reverse = reverseEdgeIds.get(edge.id);
        if(reverse === false) edge.vertices.reverse();
        return edge;
    })


    return partData;
}

let convertTo1D = function(triangles){
    let a = [];
    for(let tri of triangles) {
        for(let coords of tri){
            a.push(...coords);
        }
    }
    return a;
}

let test = async function(fresh=false){
    if(!fresh){
        let store = localStorage.getItem("test-geometry")
        if(store) return JSON.parse(store);
    }
    let documents = await getDocumentsFull();
    console.log(documents[0])
    let document = documents[0];  
    let part = document.parts[0]
    let eID = part.elementId;
    let dID = document.id
    let wID = document.defaultWorkspace.id;
    let partId = part.partId;
    let partData = reduceDataToZPlane(await getPartData(dID, wID, eID, partId));
    let triangles = getAllTriangles(partData);

    let output = {
        boudingBox: getExtremes((triangles)),
        rawTriangles: convertTo1D((triangles)),
        partData,
        triangles,
        storedOn: new Date()
    }
    localStorage.setItem("test-geometry", JSON.stringify(output));
    return output;
}

let importPart = async function(document, part){

    let documents = await getDocumentsFull();
    console.log(documents[0])
    let eID = part.elementId;
    let dID = document.id
    let wID = document.defaultWorkspace.id;
    let partId = part.partId;
    let partData = reduceDataToZPlane(await getPartData(dID, wID, eID, partId));
    let triangles = getAllTriangles(partData);

    let output = {
        boudingBox: getExtremes((triangles)),
        rawTriangles: convertTo1D((triangles)),
        partData,
        triangles,
        storedOn: new Date()
    }
    localStorage.setItem("test-geometry", JSON.stringify(output));
    return output;
}


export default {
    test,
    getPartData,
    getDocumentsFull,
    getDocuments,
    getDocument,
    importPart
}

