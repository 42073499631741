<template>
    <div v-if="documents.length > 0" class="container pt-5 pb-5">
        <h2>Select a Document <small>({{documents.length}})</small></h2>
        <p>Note: a single document can contain many parts, drawings, and assemblies. This program supports only 2d structures laying in the z=0 plane. Units are assumed to be in meters. </p>
        <div class="page">
            <div class="row">
            <div class="col-sm-3 col-lg-2" v-for="document in documents" @click="selectDocument(document)">
                <div class="document center">
                    <img class="thumb" :src="getUrl(document)" alt="">
                    <h6 class="pt-4">{{document.name}}</h6>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div v-else>
        <Texture>Getting Documents</Texture>
    </div>
</template>

<script>

import onShape from "../../FEM/onshape";
import Texture from '../loading.vue'
import talk from './../../talk.js'
import config from './../../config.js'

export default {
    components: {Texture},

    data(){
        return {
            documents: []
        }
    },
    methods:{
        getUrl(document){
            console.log(document)
            let token = talk.getToken();
            let tokenString = "?token="+token;
            let workspaceId = document.defaultWorkspace.id;
            return config.apiURL+`os/api/thumbnails/d/${document.id}/w/${workspaceId}/s/300x300`+tokenString;
        },
        selectDocument(document){
            this.$router.push({path: "/import/"+document.id})
        }
    },
    async mounted() {
        let self = this;
        onShape.getDocuments().then(({items})=>{
            self.documents = items;
        }).catch(err=>{
            self.$router.push('/ibm/list')
        })
        this.documents = (await onShape.getDocuments()).items;
        console.log(this.documents)
    }
}
</script>


<style scoped lang="scss">

.page{
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
    padding: 20px;
}

.thumb{
    width: 300px;
    margin: auto;
    display: block;
    max-width: 100%;
    min-height: 80px;
    min-width: 100%;
    background: white;
}

.center{
    text-align: center;
}

.document{
    padding: 20px;
    margin: 5px;
    border: 2px solid #ccc;
    cursor: grab;
    word-wrap: break-word;
    min-height: 120px;
    h6{
        min-height: 90px;
    }
}

.document:hover{
    border: 2px solid lightblue;

}

</style>