<template>
  <div class="container" style="max-width: 900px">
      <div v-if="user">
          <div class="card mt-5">
              <div class="card-body">
                  <h4 class="center">Sign In Successful. Welcome {{user.name}}.</h4>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import onShapeOAuth from './OnShapeOAuthRequest.js';

export default {
    data(){
        return {
            token: "",
            user: null
        }
    },
    async mounted(){ 
        console.log("here")
        this.token = this.$route.query.token;
        localStorage.setItem("token", this.token);
        this.$router.replace({'query': null}); //remove token from url
        this.user = await onShapeOAuth.saveUserDetails() 
        this.$router.push({path: "/user"})
    }
}
</script>

<style>

</style>