<template>
  <div>
    <div class="toolbar d-none">
      <div class="form-item">Immersed Boundry Method</div>

      <button @click="runSim()" class="ml-2 btn btn-primary btn-sm">
        Run Sim
      </button>
    </div>

    <div class="container p-3"> 

        <h3 v-if="completed" class="center">Solver Completed</h3>
        <h3 v-else class="center">Running Analysis...</h3>
        <h5 class="center mono">Solution Time: {{elapsed}} Sec</h5>


        <label>Solver Steps:</label>
        <ol>
          <li v-for="msg in messages">  (<span class="mono">{{msg.elapsed}} Sec</span>)  {{msg.message}}</li>
        </ol>


        <div class="mt-3" v-if="completed">
            <h5 class="center">The Analysis is complete.</h5>
        </div>

    </div>

    
  </div>
</template>

<script>
import { min, xorDependencies } from "mathjs";
import FEM from "../../FEM";
import IBM from "../../FEM/IBM";
import IBM_WORKER from "../../FEM/workers/IBMWorkerLoader.js";
import statusMessage from "../../FEM/display/status";
import valueMap from "./valueMap";
let InitScene = require("../../FEM/display/initalizeScene.js");

export default {
  components: {
    valueMap,
  },
  data() {
    return {
      startTime: null,
      clock: null,
      elapsed: 0.0,
      messages: [],
      completed: false
    };
  },
  watch: {},
  async mounted() {
    let self = this;
    let IBM_DropIn = await IBM_WORKER();
    console.log(IBM_DropIn);
    let runSim = async function () {
      let partData = JSON.parse(localStorage.getItem("test-geometry"));
      let xDiv = 101;
      if (localStorage.getItem("mesh"))
        xDiv = JSON.parse(localStorage.getItem("mesh")).xDiv;
      if (xDiv > 900) xDiv = 900;
      let backgroundMesh = await IBM_DropIn.createMesh(
        partData.boudingBox,
        xDiv
      );
      let polygon = await IBM_DropIn.makePolygonFromTriangles(
        partData.triangles
      );
      backgroundMesh = IBM.removeElements(backgroundMesh, partData, polygon);
      let results = await IBM_DropIn.getPolygonRegion(
        backgroundMesh,
        partData,
        polygon
      );
      backgroundMesh = results.mesh;
      let BC = JSON.parse(localStorage.getItem("BC"));
      let { displacement, minDisp, maxDisp, minVonMises, maxVonMises, mesh } =
        await IBM_DropIn.hook(backgroundMesh, partData, BC);
      backgroundMesh = mesh;
      statusMessage.set("Solve Completed");
      clearInterval(self.clock); self.update(); //stop the clock and update one last time;
      console.log("Displacement", displacement);
      console.log("minDisp", minDisp, "maxDisp", maxDisp);
      self.completed = true;
      setTimeout(()=>{self.$router.push("/IBM/results")}, 3000)
    };

    this.startTime = new Date();
    this.clock = setInterval(this.update, 150)
    runSim();
   
  },
  methods:{
    update(){
      let msg = statusMessage.get();
      let len = this.messages.length;
      let elapsed = (((new Date())-this.startTime)/1000).toFixed(2);
      if(len == 0 || msg != this.messages[len-1].message){
         this.messages.push({message: msg, elapsed});
      }
      this.elapsed = elapsed;
    }
  },
  beforeDestroy(){
    if(this.clock) clearInterval(this.clock)
  }
  
};
</script>


<style lang="scss" scoped>

.container{
  max-width: 550px;
}

.mono{
  font-family: monospace;
}

</style>