<template>
  <div class="container mt-4">


    <div class="row">
      <div class="col-4">
        <div class="card p-3 mb-3" style="margin: auto">
          <h5 class="center">Anaylsis Properties</h5>
          <div class="form-group">
            <label>2D Anaylsis Type</label>
            <select
              class="form-select"
              v-model="analysis.type"
              aria-label="Default select example"
            >
              <option value="stress">Plane Stress</option>
              <option value="strain">Plane Strain</option>
              <option value="axi" disabled>Axisymetric</option>
            </select>
          </div>

          <div class="form-group" v-if="analysis.type == 'stress'">
            <label
              >Geometry Thickness <span class="text-muted">[m] </span></label
            >
            <input
              type="text"
              v-model="analysis.thickness"
              class="form-control"
            />
          </div>
       
          <h5 class="center mt-5">
            Material Properties
            <h6>(Model: Linear Isotropic)</h6>
          </h5>
          <h6 class="center" v-if="analysis.material.name">
            {{ analysis.material.name }} <br />
            <small class="text-muted">{{ analysis.material.type }}</small>
          </h6>
          <div class="form-group">
            <label>Elastic Modulus <span class="text-muted">[Pa]</span></label>
            <input
              type="text"
              v-model="analysis.material.elastic"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Possions Ratio</label>
            <input
              type="text"
              v-model="analysis.material.poissons"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label
              >Density
              <span class="text-muted">[Kg/m<sup>2</sup>] </span></label
            >
            <input
              type="text"
              v-model="analysis.material.density"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Yeild Strength <span class="text-muted">[Pa]</span></label>
            <input
              type="text"
              v-model="analysis.material.yeild"
              class="form-control"
            />
          </div>
          <div class="center p-2">
            <button
              class="btn btn-primary"
              @click="createMaterialMatrix()"
              :disabled="!analysis.material.elastic"
            >
              Save
            </button>
            <button @click="clearMaterial()" class="btn">Clear</button>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="card p-3">
                <h5 class="ps-2">Material Library</h5>
        <div class="row g-0">
          <div
            class="col-auto"
            v-for="material of materials"
            @click="selectMaterial(material)"
          >
            <div class="material card m-1 p-2">
              <h6>
                {{ material.name }} <br />
                <small class="text-muted">{{ material.type }}</small>
              </h6>
              <p>
                E: {{ material.elastic.toLocaleString() }}
                <span class="text-muted">[Pa]</span> <br />
                ν: {{ material.poissons }} <br />
                ρ: {{ material.density.toLocaleString() }}
                <span class="text-muted">[Kg/m<sup>2</sup>] </span> <br />
                σ<sub>Y</sub>: {{ material.yeild.toLocaleString() }}
                <span class="text-muted">[Pa]</span>
              </p>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let materials = require("./materials.json");
import {
  linear2DPlaneStress,
  linear2DPlaneStrain,
} from "../../FEM/MaterialMatrix";
import localforage from "localforage";

export default {
  data() {
    return {
      materials,
      analysis: {
        type: "stress",
        thickness: 1,
        material: {},
      },
    };
  },
  async mounted() {
    let lastAnalysis = await localforage.getItem("analysis");
    if (lastAnalysis && lastAnalysis.type) this.analysis = lastAnalysis;
  },
  methods: {
    selectMaterial(material) {
      this.analysis.material = material;
    },
    clearMaterial() {
      this.analysis.material = {};
    },
    async createMaterialMatrix() {
      let type = this.analysis.type;
      let material = this.analysis.material;
      let C; //consitutive matrix
      if (type == "stress") {
        C = linear2DPlaneStress(
          material.elastic,
          material.poissons,
          this.analysis.thickness
        );
      } else if (type == "strain") {
        C = linear2DPlaneStrain(material.elastic, material.poissons);
      }
      localforage.setItem("CMat", C);
      localforage.setItem("analysis", this.analysis);
    },
  },
};
</script>

<style>
.material {
  min-width: 215px !important;
  min-height: 150px !important;
}
.material:hover {
  border-color: rgb(27, 126, 255);
  cursor: pointer;
  background: rgba(0, 0, 0, 0.02);
}
</style>