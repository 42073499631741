<template>
  <div>
    <div class="toolbar">
      <div class="container" style="max-width: 900px">
      <div class="row">
        <div class="col-auto">
          <label>Variable to view:</label>
          <select
            class="form-select"
            v-model="config.variable"
            aria-label="Default select example"
          >
            <option value="vonMises">Von Mises Stress</option>
            <option value="disp">Max Displacement</option>
          </select>
        </div>

        <div class="col-auto">
          <div class="form-check  text-start">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="config.showDeflection"
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Show Deformation
            </label>
          </div>

          <div class="form-check  text-start">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="config.showMesh"
              id="showMeshCheck"
            />
            <label class="form-check-label" for="showMeshCheck">
              Show Mesh
            </label>
          </div>

        </div>
        <div class="col-auto" v-if="config.showDeflection">
          <label for="autoSizingInput">Deformation Scale</label>
          <input
            type="number"
            class="form-control"
            id="autoSizingInput"
            v-model="config.scale"
          />
        </div>
     

        <div class="col-auto pt-3">
          <button @click="updateView()" class="me-2 btn btn-primary btn-sm">
            Update View
          </button>

          <button type="button" @click="screenshot()" class="btn btn-primary btn-sm">Screenshot</button>
          <button type="button"  @click="savePart()" class="btn btn-primary btn-sm">Save</button>
          <!--<button type="button" class="btn btn-primary btn-sm">Load</button> -->

          

        </div>
      </div>
    </div>
    </div>


    <div id="viewport">
      
    <div id="inset"></div>

    <div id="3d-view" class="stage" ref="stageElement"></div>

    <valueMap
      v-if="range"
      :name="name"
      :range="range"
      :units="units"
    ></valueMap>
    </div>




  </div>
</template>

<script>
import { min, xorDependencies } from "mathjs";
import FEM from "../../FEM";
import IBM from "../../FEM/IBM";
import IBM_WORKER from "../../FEM/workers/IBMWorkerLoader.js";
import Display from "../../FEM/display/FourNode";
import statusMessage from "../../FEM/display/status";
import { Lut } from "three/examples/jsm/math/Lut.js";
import valueMap from "./valueMap";
import localforage from "localforage";
import html2canvas from "html2canvas";

import * as THREE from "three";

import onShape from "../../FEM/onshape";

let InitScene = require("../../FEM/display/initalizeScene.js");




function saveAs(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);

    } else {
        window.open(uri);
    }
}


export default {
  components: {
    valueMap,
  },
  data() {
    return {
      camera: null,
      config: {
        showDeflection: true,
        scale: 100,
        variable: "vonMises",
        showMesh: false
      },
      range: null,
      units: "Pa",
      name: "Von Mises Stress",
    };
  },
  watch: {},
  methods: {
    plotMesh() {
      // this.clearGrid();
    },
    clear() {
      let scene = this.scene;
      scene.clear();
      return;
    },
    updateView() {
      this.clear();
      this.runSim();
    },
    screenshot(){
      let filename = this.name.replace(/ /g,'') + "_" + this.units + ".png";
      html2canvas(document.getElementById("viewport")).then(canvas => {
        saveAs(canvas.toDataURL(), filename);
      });
    },
    async savePart(){

      let canvas = await html2canvas(document.getElementById("viewport"))
      let imageData = canvas.toDataURL();
      localStorage.setItem("partImage", imageData);
      this.$router.push("/ibm/save")

    }
  },
  async mounted() {
    let self = this;
    let { controls, camera, runtime, scene, renderer } = InitScene(true);

    let IBM_DropIn = await IBM_WORKER();

    console.log(IBM_DropIn);
    let runSim = async function () {
      console.time("OnShape Request");
      let partData = await onShape.test();
      console.timeEnd("OnShape Request");
      console.log("PartData", partData);

      Display.plotEdgesThin(scene, partData, 0.0001, 0xa020f0);
      let backgroundMesh = JSON.parse(await localforage.getItem("results"));
      if (!backgroundMesh) alert("Results missing");
      console.log(backgroundMesh);
      let { minDisp, maxDisp, minVonMises, maxVonMises } =
        backgroundMesh.results;

      let scale = self.config.scale;
      if (self.config.showDeflection == false) scale = 1e-12;

      console.log("scale!", scale);

      let showDisplacement = true;
      if(self.config.variable == "vonMises"){
        showDisplacement = false;
      }
      let range, allTrangulations, rawTrangles, colors;

      if (showDisplacement) {
        range = [minDisp, maxDisp];
        let res = await IBM_DropIn.getDisplacedGraphics(
          backgroundMesh,
          partData,
          scale,
          false,
          range,
          false,
          false
        );
        allTrangulations = res.allTrangulations;
        colors = res.colors;
        rawTrangles = res.rawTrangles;
        this.range = range.map((x) => x * 1000);
        this.units = "mm";
        this.name = "Displacement Magnitude";
      } else {
        range = [minVonMises, maxVonMises];
        let res = await IBM_DropIn.getDisplacedGraphics(
          backgroundMesh,
          partData,
          scale,
          false,
          range,
          true,
          true
        );
        this.range = range.map((x) => x / 1e6);
        this.units = "MPa";
        this.name = "Von Mises Stress";
        allTrangulations = res.allTrangulations;
        colors = res.colors;
        rawTrangles = res.rawTrangles;
      }

      let showInside = true;
      console.log("About to start plotting");
      if(backgroundMesh.elements.length > 10000) showInside = false
      if(self.config.showMesh) Display.CreateWireframe(scene, backgroundMesh, null, scale, null, true, showInside); //backgroundMesh
      Display.plotColoredTriangles(scene, rawTrangles, colors); //boundryTriangles
      //Display.plotTriangles(scene, verts2); //boundryTriangles
      //Display.plotTriangles(scene, partData.rawTriangles, 255); //partGeomertry
      statusMessage.set("Display Completed");
    };

    self.runSim = runSim;
    self.scene = scene;
    this.camera = camera;
    let lastSize = null;

    let updateCanvasSize = function (self) {
      let width = document.getElementById("3d-view").clientWidth;
      let height = document.getElementById("3d-view").clientHeight;
      if (lastSize != width + height) {
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        lastSize = width + height;
      }
    };
    //repeat at 60fps
    let render = function (self) {
      controls.update();
      updateCanvasSize(self);
      runtime();
      renderer.render(scene, camera);
      requestAnimationFrame(render);
    };
    render(this);
    self.runSim();
  },
};
</script>


<style lang="scss">
.stage {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  canvas {
  }
}

.form-item {
  display: inline-block;
  input {
    width: 60px;
  }
}

.form-item.spaced {
  border-left: 1px solid white;
  padding-left: 10px;
  margin-left: 10px;
}

.toolbar {
  background: rgb(31, 31, 31);
  width: 100%;
  color: white;
  padding: 15px;
  font-size: 12px;
  margin: auto;
  text-align: center;
}

#inset {
  position: absolute;
}
.stageContainer {
}
</style>