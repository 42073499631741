<template>
  <div>
    <div class="toolbar">
      <div class="form-item">Mesh Selection |</div>
      <div class="form-item">
        Node Count: {{ nodeCount.toLocaleString() }} |
      </div>
      <div class="form-item">
        Element Count: {{ elementCount.toLocaleString() }} |
      </div>
       <div class="form-item">
        2D Area: {{ area }} mm<sup>2</sup>   |
      </div>

      <div class="form-item">
        X-Dir Mesh Divisions:
        <input
          type="number"
          step="1"
          max="900"
          v-model="config.xElementCount"
        />
        |
      </div>
      <button
        @click="setMesh(config.xElementCount)"
        class="ml-2 btn btn-primary btn-sm"
      >
        Update Mesh
      </button>
    </div>
    <div id="inset"></div>
    <div id="3d-view" class="stage" ref="stageElement"></div>
  </div>
</template>

<script>
import IBM from "../../FEM/IBM";
import Display from "../../FEM/display/FourNode";
import statusMessage from '../../FEM/display/status';
import IBM_WORKER from "../../FEM/workers/IBMWorkerLoader.js";
import onShape from "../../FEM/onshape";
let InitScene = require("../../FEM/display/initalizeScene.js");

export default {
  data() {
    let xDiv = 101;
    if (localStorage.getItem("mesh"))
      xDiv = JSON.parse(localStorage.getItem("mesh")).xDiv;
    if (xDiv > 900) xDiv = 900;
    return {
      camera: null,
      config: {
        E: 10 * 10e9,
        v: 0.3,
        thickness: 0.1,
        xDiv: 250,
        yDiv: 9,
        length: 6,
        height: 0.1,
        lowerIntergration: false,
        tipLoad: 500000,
        enableDynamic: false,
        plotMirror: true,
        ratio: 1,
        xElementCount: xDiv,
      },
      elementCount: 0,
      nodeCount: 0,
      area: 0
    };
  },
  watch: {},
  methods: {
    plotMesh() {
      // this.clearGrid();
    },
    clear() {
      let scene = this.scene;
      scene.clear();
    },
    runSim() {
      if (
        this.config.enableDynamic &&
        this.config.xDiv * this.config.yDiv > 2000
      ) {
        alert("Dynamic Sim is Limited to 2000 elements");
        this.config.enableDynamic = false;
      }
    },
    async setMesh(xDiv = 100) {
      if (xDiv > 900) xDiv = 900;
      this.config.xElementCount = xDiv;
      this.$forceUpdate();
      this.clear();
      xDiv = Number(xDiv);
      let scene = this.scene;
      console.time("OnShape Request");
      let partData = await onShape.test(); //console.log("PartData", partData);



      console.timeEnd("OnShape Request");
      //Display.plotEdgesThin(scene, partData , 0.0001, 0xA020F0);




      console.time("Generate Mesh");

      let backgroundMesh = IBM.createMesh(partData.boudingBox, xDiv);
      console.timeEnd("Generate Mesh");
      let orginalElements = backgroundMesh.elements;



      console.time("Polygons Elements");
      let polygon = IBM.makePolygonFromTriangles(partData.triangles);
      console.timeEnd("Polygons Elements");






      console.time("Remove Elements");
      backgroundMesh = IBM.removeElements(backgroundMesh, partData, polygon);
      this.elementCount = backgroundMesh.elements.length;

      let isNodeUsed = new Array(backgroundMesh.nodes.length).fill(false);
      backgroundMesh.elements.map((element) => {
        for (let nodeIndex of element.nodes) {
          isNodeUsed[nodeIndex] = true;
        }
      });
      let nodeCount = isNodeUsed.reduce((acc, cur) => {
        if (cur) acc++;
        return acc;
      }, 0);
      this.nodeCount = nodeCount;

      console.timeEnd("Remove Elements");
     

      let results = await this.IBM_DropIn.getPolygonRegion(
        backgroundMesh,
        partData,
        polygon,
        true,
        true
      );
      backgroundMesh = results.mesh;

    let allEdges = IBM.combineEdges(backgroundMesh.elements);
    for(let edge of allEdges) {
      let c = Math.random() * 0xffffff;
      Display.plotVerticesThinWithNormal(scene, edge, 0, c)
    }

      this.area = (results.totalArea*1000*1000).toLocaleString();

      let showInside = false;
      let showMesh = true;
      if (nodeCount < 15000) {
        showInside = true;
      }

      console.log("About to start plotting");
      if(showMesh){
        Display.CreateWireframe(
        scene,
        backgroundMesh,
        null,
        10000,
        undefined,
        true,
        showInside
      ); //backgroundMesh
      }
      Display.plotTriangles(scene, results.allTrangulations.flat().map(x=>{x[2]=0; return x}).flat(), 230); //boundryTriangles

      localStorage.setItem("mesh", JSON.stringify({ xDiv }));
      //localStorage.setItem("backgroundMesh", JSON.stringify( backgroundMesh ));
      statusMessage.set("Mesh Generated successfully.");
    

    },
  },

  async mounted() {
    let { controls, camera, runtime, scene, renderer } = InitScene();
    let loadWorker = IBM_WORKER();
    this.scene = scene;
    this.camera = camera;
    let self = this;


    let lastSize = null;
    let updateCanvasSize = function () {
      let width = self.$refs.stageElement.clientWidth;
      let height = self.$refs.stageElement.clientHeight;
      if (lastSize != width + height) {
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        lastSize = width + height;
      }
    };
    //repeat at 60fps
    let render = function () {
      controls.update();
      updateCanvasSize();
      runtime();
      renderer.render(scene, camera);
      requestAnimationFrame(render);
    };
    render();


    this.IBM_DropIn = await loadWorker;
    await this.setMesh(this.config.xElementCount);

  },
};
</script>


<style lang="scss">
.stage {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  canvas {
  }
}

.form-item {
  font-size: 13px;
  display: inline-block;
  input {
    width: 60px;
  }
}

.form-item.spaced {
  border-left: 1px solid white;
  padding-left: 10px;
  margin-left: 10px;
}

.toolbar {
  background: rgb(31, 31, 31);
  width: 100%;
  color: white;
  padding: 15px;
  font-size: 12px;
  margin: auto;
  text-align: center;
}

#inset {
  position: absolute;
}
.stageContainer {
}
</style>