import { spawn, Thread, Worker, Pool } from "threads";
import statusMessage from './../display/status';

let worker = null;


let workerRuntime = async function () {
    if (
      typeof WorkerGlobalScope !== "undefined" &&
      self instanceof WorkerGlobalScope
    ) { return };
    if(worker) return worker; // return if already initialized
    let solverWorker = await spawn(new Worker("./IBM.js"));
    worker = solverWorker;
    Thread.events(solverWorker).subscribe((event) => {
      if(event.data.type == "update"){
        statusMessage.set(event.data.msg);
      }
    });
    console.log("Worker Initialized");
    return solverWorker;
};


export default workerRuntime; //run all FEM in a worker;