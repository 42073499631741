import mathjs from "mathjs";
import * as THREE from "three";
import { Lut } from "three/examples/jsm/math/Lut.js"
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'meshline';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';


let getColor = function (lut, d) {
  return lut.getColor(d).toArray()
}

let ShapeFunction = function (s, t) { // Iso 4-node [kim]
  return [
    (1 - s) * (1 - t) / 4, (1 + s) * (1 - t) / 4, (1 + s) * (1 + t) / 4, (1 - s) * (1 + t) / 4
  ]
}

let plotLine = function (scene, points, color, modify = true, linewidth = 1) {
  points = points.map(x => {
    if (modify) x[2] = 0.0007;
    return new THREE.Vector3(...x)
  })
  const material = new THREE.LineBasicMaterial({ color: color || 0x0000ff, linewidth: linewidth });
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  const line = new THREE.Line(geometry, material);
  line.isGrid = true;
  scene.add(line);
}


let plotTriangles = function (scene, verts, color) {
  const geometry = new THREE.BufferGeometry();
  // create a simple square shape. We duplicate the top left and bottom right
  // vertices because each vertex needs to appear once per triangle.
  var colors = new Float32Array(verts.length);
  const vertices = new Float32Array(verts);
  // itemSize = 3 because there are 3 values (components) per vertex
  geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
  var colors = new Uint8Array(vertices.length);
  for (let i in colors) colors[i] = (color || 0);
  // Don't forget to normalize the array! (third param = true)
  geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3, true));
  const material = new THREE.MeshBasicMaterial({ vertexColors: true, side: THREE.DoubleSide });
  const mesh = new THREE.Mesh(geometry, material);
  // const wireframe = new THREE.WireframeGeometry(geometry)
  // const line = new THREE.LineSegments( wireframe );
  // line.material.depthTest = false;
  // line.material.opacity = 0.25;
  // line.material.transparent = true;
  scene.add(mesh);


}


let plotColoredTriangles = function (scene, verts, colorsImport) {
  const geometry = new THREE.BufferGeometry();
  // create a simple square shape. We duplicate the top left and bottom right
  // vertices because each vertex needs to appear once per triangle.
  const vertices = new Float32Array(verts);
  // itemSize = 3 because there are 3 values (components) per vertex
  geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
  var colors = new Uint8Array(colorsImport);
  // Don't forget to normalize the array! (third param = true)
  geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3, true));
  const material = new THREE.MeshBasicMaterial({ vertexColors: true, side: THREE.DoubleSide });
  const mesh = new THREE.Mesh(geometry, material);
  console.log(geometry)
  // const wireframe = new THREE.WireframeGeometry(geometry)
  // const line = new THREE.LineSegments( wireframe );
  // line.material.depthTest = false;
  // line.material.opacity = 0.25;
  // line.material.transparent = true;
  scene.add(mesh);
}


let addNode = function (scene, position, size = 8) {
  let color = 0x0000ff; //blue
  let dotPos = [...position];

  const geometry = new THREE.SphereGeometry(size, 32, 16);
  const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
  const sphere = new THREE.Mesh(geometry, material);
  sphere.position.set(dotPos[0], dotPos[1], 0);
  scene.add(sphere);

}

let plotPoints = function (scene, points) {
  for (let point of points) {
    addNode(scene, point);
  }
}

let CreateWireframe = function (scene, testMesh, disp, scaler, color, showIssues = false, showInside = false) {
  if (!scaler) scaler = 1;
  let nodes = testMesh.nodes;

  for (let element of testMesh.elements) {
    let i = 0;
    let points = [];
    let lineColor = color;
    if (element.outside == true) lineColor = 0x00FF00;
    if (element.issue == true) lineColor = 0XFFFF00;
    if (element.majorIssue == true) lineColor = 0XFF0000;

    for (let nodeIndex of element.nodes) {
      let node = testMesh.nodes[nodeIndex];
      let pos = node.orgPos;
      if (node.disp) {
        pos = [node.orgPos[0] + scaler * node.disp[0], node.orgPos[1] + scaler * node.disp[1]]
      } else if (disp) {
        pos = [node.orgPos[0] + scaler * disp[nodeIndex][0], node.orgPos[1] + scaler * disp[nodeIndex][1]]
      }
      //addNode(scene, pos, i);
      points.push(pos)
      i++;
    }

    points.push(points[0])
    if ((element.outside || showInside) && (!element.issue || showIssues)) plotLine(scene, points, lineColor);
  }
}



function filterOutliers(someArray) {

  // Copy the values, rather than operating on references to existing values
  var values = someArray.concat();

  // Then sort
  values.sort(function (a, b) {
    return a - b;
  });

  /* Then find a generous IQR. This is generous because if (values.length / 4) 
   * is not an int, then really you should average the two elements on either 
   * side to find q1.
   */
  var q1 = values[Math.floor((values.length / 4))];
  // Likewise for q3. 
  var q3 = values[Math.ceil((values.length * (3 / 4)))];
  var iqr = q3 - q1;

  // Then find min and max values
  var maxValue = q3 + iqr * 1.5;
  var minValue = q1 - iqr * 1.5;

  // Then filter anything beyond or beneath these values.
  var filteredValues = values.filter(function (x) {
    return (x <= maxValue) && (x >= minValue);
  });

  // Then return
  return filteredValues;
}


function arrayMin(arr) {
  var len = arr.length, min = Infinity;
  while (len--) {
    if (arr[len] < min) {
      min = arr[len];
    }
  }
  return min;
};

function arrayMax(arr) {
  var len = arr.length, max = -Infinity;
  while (len--) {
    if (arr[len] > max) {
      max = arr[len];
    }
  }
  return max;
};


let stressSmoothing = function (mesh) {



  let nodal_stresses_full = [];


  for (let el of mesh.elements) {
    for (let i in el.nodes) {
      let node = el.nodes[i];
      if (!nodal_stresses_full[node]) nodal_stresses_full[node] = [];
      nodal_stresses_full[node].push(el.values[i]);
    }
  }

  let nodalStress = [];
  for (let i in nodal_stresses_full) {
    let stressValues = nodal_stresses_full[i];
    let sum = stressValues.reduce(function (a, b) { return a + b; }, 0);
    let avg = sum / stressValues.length;
    nodalStress[i] = avg;
  }



  return nodalStress;


}

let CreateMesh = function (inputMesh, disp, scene, dispValues) {

  let nodalValues = [];
  for (let element of inputMesh.elements) {

    if (dispValues) {
      let avg = 0;
      element.values = element.nodes.map(nodeIndex => {
        let d = disp[nodeIndex];
        let val = Math.sqrt(d[0] * d[0] + d[1] * d[1]);
        avg += val;
        return val
      })
      element.centerStress = avg / 4
    }

    nodalValues.push(...element.values)
  };

  let smoothNodeStresses = stressSmoothing(inputMesh)
  nodalValues = filterOutliers(nodalValues)
  const lut = new Lut('rainbow', 200);
  let useStress = true;
  let min = 0;
  let max = 0;
  if (nodalValues) max = arrayMax(smoothNodeStresses);
  if (nodalValues) min = arrayMin(smoothNodeStresses);
  lut.setMax(max - 0.3 * max)
  lut.setMin(min + 0.3 * min)
  let nodes = inputMesh.nodes;
  const geometry = new THREE.BufferGeometry();
  // create a simple square shape. We duplicate the top left and bottom right
  // vertices because each vertex needs to appear once per triangle.
  const vertices = new Float32Array(inputMesh.elements.length * 4 * 3 * 3);
  const colors = new Float32Array(inputMesh.elements.length * 4 * 3 * 3);
  let i = 0;
  for (let el of inputMesh.elements) {

    let N = ShapeFunction(0, 0);
    let u0 = [nodes[el.nodes[0]].orgPos[0], nodes[el.nodes[1]].orgPos[0], nodes[el.nodes[2]].orgPos[0], nodes[el.nodes[3]].orgPos[0]];
    let v0 = [nodes[el.nodes[0]].orgPos[1], nodes[el.nodes[1]].orgPos[1], nodes[el.nodes[2]].orgPos[1], nodes[el.nodes[3]].orgPos[1]];
    let x0 = N[0] * u0[0] + N[1] * u0[1] + N[2] * u0[2] + N[3] * u0[3]
    let y0 = N[0] * v0[0] + N[1] * v0[1] + N[2] * v0[2] + N[3] * v0[3]


    let a = [];
    let c = [];
    a.push(...nodes[el.nodes[0]].orgPos, 0)
    a.push(x0, y0, 0)
    a.push(...nodes[el.nodes[1]].orgPos, 0)

    a.push(...nodes[el.nodes[1]].orgPos, 0)
    a.push(x0, y0, 0)
    a.push(...nodes[el.nodes[2]].orgPos, 0)

    a.push(...nodes[el.nodes[2]].orgPos, 0)
    a.push(x0, y0, 0)
    a.push(...nodes[el.nodes[3]].orgPos, 0)

    a.push(...nodes[el.nodes[3]].orgPos, 0)
    a.push(x0, y0, 0)
    a.push(...nodes[el.nodes[0]].orgPos, 0)

    let values = el.values;
    if (smoothNodeStresses) {
      let i = 0;
      values = values.map(val => {
        return smoothNodeStresses[el.nodes[i++]]
      })
    }

    if (el.values) {
      c.push(...getColor(lut, values[0]));
      c.push(...getColor(lut, el.centerStress))
      c.push(...getColor(lut, values[1]));

      c.push(...getColor(lut, values[1]));
      c.push(...getColor(lut, el.centerStress))
      c.push(...getColor(lut, values[2]));

      c.push(...getColor(lut, values[2]));
      c.push(...getColor(lut, el.centerStress))
      c.push(...getColor(lut, values[3]));

      c.push(...getColor(lut, values[3]));
      c.push(...getColor(lut, el.centerStress))
      c.push(...getColor(lut, values[0]));
    }

    for (let j in a) {
      vertices[i] = a[j];
      colors[i] = c[j];
      i++;
    }
  }
  // itemSize = 3 because there are 3 values (components) per vertex
  geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
  if (disp) geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
  geometry.computeFaceNormals();
  geometry.computeVertexNormals();
  const material = new THREE.PointsMaterial({ vertexColors: true, side: THREE.DoubleSide });
  const mesh = new THREE.Mesh(geometry, material);
  scene.add(mesh);
  return mesh;
}

let addDisplacment = function (nodes, displacements, scaler) {
  let newNodes = [];
  if (!scaler) scaler = 1000;
  for (let i in nodes) {
    let d = displacements[i];
    newNodes.push({
      orgPos: [
        nodes[i].orgPos[0] + d[0] * scaler,
        nodes[i].orgPos[1] + d[1] * scaler,
      ],
    });
  }
  return newNodes;
};


let plotMeshLine = function (scene, points, color, edgeId) {
  points = points.flat()
  // Line2 ( LineGeometry, LineMaterial )
  const geometry = new LineGeometry();
  geometry.setPositions(points);
  //geometry.setColors( colors );

  let matLine = new LineMaterial({
    color,
    linewidth: 10 / 1000, // in world units with size attenuation, pixels otherwise
    //resolution:  // to be set by renderer, eventually
    dashed: false,
    alphaToCoverage: true,
    worldUnits: false
  });
  matLine.worldUnits = false;

  let line = new Line2(geometry, matLine);
  line.edgeId = edgeId;
  line.computeLineDistances();
  scene.add(line);
}


let plotEdge = function (scene, vertices, c, id) {
  let color = c || 0xA020F0;
  plotMeshLine(scene, vertices, color, id)
}


let plotEdges = function (scene, part, offset = 0) {
  let partData = part.partData;
  let edges = partData.edges;
  let edgeColors = edges.map(e => Math.random() * 0xffffff)
  for (let i in edges) {
    let edge = edges[i];
    for (let vert of edge.vertices) vert[2] += offset;
    plotEdge(scene, edge.vertices, edgeColors[i], edge.id);
  }
}

let plotEdgesThin = function (scene, part, offset = 0, c) {
  let partData = part.partData;
  let edges = partData.edges;
  for (let i in edges) {
    let edge = edges[i];
    for (let vert of edge.vertices) vert[2] += offset;
    plotLine(scene, edge.vertices, c);
  }
}

let plotVerticesThin = function (scene, vertices, offset = 0, c) {
  if (offset !== 0) for (let vert of vertices) vert[2] += offset;
  plotLine(scene, vertices, c);

}

let crossProduct = function ([a1, a2, a3], [b1, b2, b3]) {
  return [a2 * b3 - a3 * b2, a3 * b1 - a1 * b3, a1 * b2 - a2 * b1]
}


let plotVerticesThinWithNormal = function (scene, segment, offset = 0, c) {
  for (let point of segment) point[2] = 0;
  let vector = [segment[1][0] - segment[0][0], segment[1][1] - segment[0][1], 0];
  let zDir = [0, 0, 1];
  let normal = crossProduct(zDir, vector);
  let midPointX = (segment[0][0] + segment[1][0]) / 2;
  let midPointY = (segment[0][1] + segment[1][1]) / 2;

  //addNode(scene, [segment[1][0], segment[1][1], 0], null, 0.001);

  {
    const dir = new THREE.Vector3(normal[0], normal[1], 0);
    //normalize the direction vector (convert to vector of length 1)
    dir.normalize();
    const length = 0.005;
    const origin = new THREE.Vector3(midPointX, midPointY, 0);
    const hex = 0xff0000;
    const arrowHelper = new THREE.ArrowHelper(dir, origin, length, hex);
    scene.add(arrowHelper)
  }


  plotLine(scene, segment, c);

}




let plotBC = function (scene, part, edgeId, BC) {
  let edgeData;
  let type = BC.type;
  for (let edge of part.partData.edges) {
    if (edge.id == edgeId) { edgeData = edge; break; }
  }
  console.log(part)

  let delta = part.boudingBox.maxs.map((v, i) => v - part.boudingBox.mins[i]);
  let length = Math.max(...delta) * 0.02;


  if (!edgeData) throw "Edge not found";
  let vertices = edgeData.vertices;


  for (let point of vertices) {


    if (type == "traction") {
      const dir = new THREE.Vector3(BC.value.x, BC.value.y, 0);
      //normalize the direction vector (convert to vector of length 1)
      dir.normalize();
      const origin = new THREE.Vector3(...point);
      const hex = 0xff0000;
      const arrowHelper = new THREE.ArrowHelper(dir, origin, length, hex);
      //scene.add( arrowHelper )
    } else {

      let factor = length;
      const shape = new THREE.Shape();
      const x = point[0];
      const y = point[1];


      if (BC.value.x == false || BC.value.y == false) {
        shape.moveTo(x, y);
        if (BC.value.x == true) {
          shape.lineTo(x + factor, y + factor);
          shape.lineTo(x + factor, y - factor);
        } else if (BC.value.y == true) {
          shape.lineTo(x - factor, y - factor);
          shape.lineTo(x + factor, y - factor);
        }
        const TriangleGeometry = new THREE.ShapeGeometry(shape);
        const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        const tri = new THREE.Mesh(TriangleGeometry, material);
        scene.add(tri);
      } else {

        addNode(scene, point, length)

      }



    }

  }

  console.log(vertices, edgeData.id)


}

module.exports = { CreateMesh, CreateWireframe, addDisplacment, plotPoints, plotTriangles, plotColoredTriangles, plotEdges, plotBC, plotEdgesThin, plotVerticesThin, plotVerticesThinWithNormal }