import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
let store = require("store");
global.THREE = THREE;
const OrientationGizmo = require("three-orientation-gizmo");


module.exports = function (preserveDrawingBuffer = false) { //takes in three.js scene


  let scene = new THREE.Scene();
  let renderer = new THREE.WebGLRenderer({antialias: true, preserveDrawingBuffer  });
  document.getElementById("3d-view").appendChild(renderer.domElement);

  let camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.01,
    1000
  );
  let lastCameraPos = store.get("camera");
  if (lastCameraPos) {
    let p = lastCameraPos.position;
    let r = lastCameraPos.rotation;
    camera.position.set(p.x, p.y, p.z);
    camera.rotation.set(r._x, r._y, r._z);
  }
  scene.add(new THREE.AmbientLight(0xffffff, 10));
  scene.background = new THREE.Color(0xdbdbff);
  //initalize
  let controls = new OrbitControls(camera, renderer.domElement);
  controls.update();
  camera.position.z = 1.5;
  const light = new THREE.PointLight(0xffffff, 10, 200);
  light.position.set(0, 0, 5);
  scene.add(light);

  let orientationGizmo = new OrientationGizmo(camera, {
    size: 100,
    padding: 20,
    bubbleSizeSeconday: 0.001,
  });
  document.getElementById('inset').appendChild(orientationGizmo);

  let runtime = function(){
    orientationGizmo.update();
  }


  return { controls, camera, runtime, scene, renderer }
};