<template>
  <div class="container pt-5 pb-5">
    <h2>Example Documents:</h2>
    <div v-if="!loading" class="d-flex flex-wrap">
      <div
        class="model-view"
        v-for="doc in documents"
        v-if="doc.name"
        @click="importPart(doc._id)"
      >
        <div class="fw-bold center"><small>(#{{doc.id}})</small> {{ doc.name }}</div>
        {{ doc.description }}
        <img class="preview" :src="getUrl(doc._id)" alt="" />
        <div class="center">{{new Date(doc.created).toLocaleDateString()}} | <span v-if="doc.size">{{doc.size}}</span></div>
  </div>

</div>
<div v-else>
    <Texture>Importing Document</Texture>
</div>
</div>

</template>

<script>

import Texture from "../loading.vue";
import talk from "./../../talk.js";
import config from "./../../config.js";

export default {
  components: { Texture },

  data() {
    return {
      documents: [],
      name: "",
      loading: false
    };
  },
  methods: {
    getUrl(id) {
      return config.apiURL+"static/model_" + id + ".png";
    },
    async importPart(id){
        this.loading =true;
        await talk.importPart(id);
        this.$router.push("/ibm/results")
        this.loading = false;
    }
  },
  async mounted() {
    let res = await talk.get("list");
    console.log(res);
    this.documents = res.data;
  },
};
</script>


<style scoped lang="scss">
.page {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
  padding: 20px;
}

.model-view{
    min-width: 300px;
    max-width: 100%;
    border: 2px solid black;
    margin: 8px;
    padding: 5px 0px;
}

.model-view:hover{
    border-color: #1851CA;
}

.thumb {
  width: 300px;
  margin: auto;
  display: block;
  max-width: 100%;
  min-height: 80px;
  min-width: 100%;
  background: white;
}

.preview {
  max-width: 300px;
  display: block;
  width: 100%;
  padding: 5px 0px;
}

.center {
  text-align: center;
}

.document {
  padding: 20px;
  margin: 5px;
  border: 2px solid #ccc;
  cursor: grab;
  word-wrap: break-word;
  min-height: 120px;
  h6 {
    min-height: 90px;
  }
}

.document:hover {
  border: 2px solid lightblue;
}
</style>