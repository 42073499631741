
let set = function(message){
    localStorage.setItem('statusMessage', message);
};

let get = function(){
    return localStorage.getItem('statusMessage') || "System Idle";
};

set("System Idle");

export default {
    set, get
}
