<template>

<div class="barContainer">
    <div class="title" v-if="name"><b>{{name}}</b></div>
    <div class="rangeValue">[{{units}}]</div>
    <div class="rangeValue">{{values[1]}}</div>
    <img class="tempBar" v-bind:src="imageBytes"/>
    <div class="rangeValue">{{values[0]}}</div>
</div>
  
</template>

<script>
import { Lut } from "three/examples/jsm/math/Lut.js"

export default {
    props: ['lut', 'range', 'units', 'name'],
    data(){
        return {
            imageBytes: ""
        }
    },
    mounted(){
        let lut = this.lut;
        if(!lut) lut = new Lut('rainbow', 512 );
        let cc = lut.createCanvas();
        this.imageBytes = cc.toDataURL();
    },
    computed:{
        values(){
            let range = this.range;
            return range.map(x => {
                x = Math.abs(x);
                if(0.1 < x && x < 10){
                    return x.toFixed(2);
                }else if(x==0){
                    return "0";
                }else if(0.1 < x && x < 1000){
                    return x.toFixed(1);
                }else{
                    return x.toExponential(2)
                }

            });
        }
    }

}
</script>

<style>
.tempBar{
    width: 30px;
    height: 50vh;
    display: block;
    margin: auto;
}
.barContainer{
    max-width: 100px;
    text-align: center;
    position: fixed;
    bottom: 15vh;
    right: 0;
    background: rgba(255, 255, 255, 0.486);
    padding: 5px;
    user-select: none;
}
.rangeValue{
    font-size: 13px;
    color: black;
    font-family: monospace;
}
.title{
    font-family: inherit;
    font-size: 14px;

}
</style>